import Text from '@nord/ui/src/components/Text'
import React from 'react'

import Table from '../../../../../components/Table'

import columns from './columns'

export interface Props {
  data?: any[] // TODO: PropTypes.shape
}

const GeneralOrderDataTable = ({ data }: Props) => (
  <>
    <Text as="h5">Ordre</Text>
    <Table data={data} columns={columns} />
  </>
)

GeneralOrderDataTable.defaultProps = {
  data: [],
}

export default GeneralOrderDataTable
