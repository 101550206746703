import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import z from 'zod'

import useCurrentPortfolioIds from '../../../../hooks/useCurrentPortfolioIds'
import useDataChannel from '../../../../hooks/useDataChannel'
import type {
  CustomGenerateAllocationDataPayloadType,
  CustomOptionsType,
  CustomTradeTypeType,
} from '../../../../types'
import { CustomPortfolioAllocationsChannelData } from '../../../../types/PortfolioAllocationsChannelData'
import { assertZod } from '../../../../utils'
import { portfoliosAllocationDataAtom } from '../atoms'

const usePortfolioAllocationsDataChannel = () => {
  const currentPortfolioIds = useCurrentPortfolioIds()

  const [isGenerating, setIsGenerating] = useState(false)

  const { subscribe, unsubscribe, send } = useDataChannel({
    channel: 'PortfoliosAllocationDataChannel',
    topic: [...currentPortfolioIds].sort().join(','),
  })

  const setPortfoliosAllocation = useSetAtom(portfoliosAllocationDataAtom)

  useEffect(() => {
    subscribe({
      received(res) {
        const { data } = assertZod(z.object({ data: CustomPortfolioAllocationsChannelData }), res)
        setPortfoliosAllocation(data)
        setIsGenerating(false)
      },
    })

    return unsubscribe
  }, [setPortfoliosAllocation, subscribe, unsubscribe])

  const generateAllocationData = useCallback(
    (tradeType: CustomTradeTypeType, options: CustomOptionsType) => {
      const payload: CustomGenerateAllocationDataPayloadType = {
        portfoliosData: currentPortfolioIds.map((id) => ({ id, options })),
        tradeType,
      }

      send({ action: 'generate_allocation_data', payload })
      setIsGenerating(true)
    },
    [currentPortfolioIds, send],
  )

  return { isGenerating, generateAllocationData }
}

export default usePortfolioAllocationsDataChannel
