import Card from '@nord/ui/src/components/Card'
import React from 'react'
import Col, { type ColProps } from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export interface PageProps extends ColProps {
  children?: React.ReactNode
  withCard?: boolean
}

const Page = ({ children, withCard, ...otherProps }: PageProps) => {
  let content = children

  if (withCard)
    content = (
      <Card>
        <Card.Body>{content}</Card.Body>
      </Card>
    )

  return (
    <Row>
      <Col className="px-2 px-sm-3" {...otherProps}>
        {content}
      </Col>
    </Row>
  )
}

Page.defaultProps = {
  children: undefined,
  withCard: false,
}

export default Page
