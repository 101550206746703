import { useApi } from '@nord/ui/src/hooks'
import { useApiRequest } from '@nord/ui/src/hooks/useApi'
import constate from 'constate'
import { useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { pageSize as initialPageSize } from '../../../components/Pagination/constants'

import useInvestablesPreferences from './useInvestablesPreferences'

interface EnumType {
  label?: string
  value?: string
}
export interface Investable {
  cashBalance?: number
  correctDocumentsSignedForSimple?: string | boolean
  depotType: string
  esg?: boolean
  products?: EnumType[]
  lastDeposit?: number
  lastDepositDate?: string
  orderHandlingAccepted?: boolean
  riskScore?: number
  saxoBankAccountId?: string
  saxoClientId?: string
  suitable?: boolean
  tradingInfoComment?: string
  userDescription?: string
  pendingOrders: boolean
  portfolioId: number
}

export interface InvestablePortfoliosContextType {
  alertData?: { message: string; variant: string }
  apiFilter: Record<string, string>
  loading: boolean
  pageCount: number
  pageSize: number
  showAlert: boolean
  onAlertDataChange(alert: { message: string; variant: string }): void
  onHideAlert(): void
  onShowAlert(): void
  refetchInvestablePortfolios(data?: unknown): Promise<unknown>
  setApiFilter(filter: Record<string, string>): void
  setPageSize(size: number): void
}

const useInvestablePortfolios = () => {
  const {
    filters: [userFilters],
  } = useInvestablesPreferences()

  const [apiFilter, setApiFilter] = useState(() =>
    userFilters.reduce((acc, filter) => {
      acc[filter.id] = filter.value

      return acc
    }, {} as Record<string, string>),
  )

  const [showAlert, setShowAlert] = useState(false)
  const [alertData, setAlertData] = useState<{ message: string; variant: string }>()

  const handleShowAlert = () => setShowAlert(true)
  const handleHideAlert = () => setShowAlert(false)

  const fetchInvestablePortfolios = useApi('/limited_admin/portfolios/investables', {
    withCredentials: true,
  })

  const {
    data,
    loading,
    request: refetchInvestablePortfolios,
  } = useApiRequest(fetchInvestablePortfolios, {
    enableDebounce: true,
    debounceTimeout: 500,
  })

  const { data: investablePortfoliosData, meta } = data || {}
  const { pagination } = meta || {}
  const { last: pageCount } = pagination || {}

  const [pageSize, setPageSize] = useLocalStorage('userPageSize', initialPageSize)

  return {
    alertData,
    apiFilter,
    investablePortfoliosData: investablePortfoliosData as Investable[] | undefined,
    loading,
    pageCount,
    pageSize,
    showAlert,
    onAlertDataChange: setAlertData,
    onHideAlert: handleHideAlert,
    onShowAlert: handleShowAlert,
    refetchInvestablePortfolios,
    setApiFilter,
    setPageSize,
  }
}

export const [InvestablePortfoliosProvider, useInvestablePortfoliosContext] =
  constate(useInvestablePortfolios)
