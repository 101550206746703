import { atom } from 'jotai'
import { mapValues } from 'lodash'

import type {
  CustomOptionsType,
  CustomPortfolioAllocationsChannelDataType,
} from '../../../../types'

export const afterTradeAtom = atom({
  cashAmount: '0',
  allocation: {} as Record<string, string>,
})

export const afterTradeDifferenceAtom = atom({
  cashAmount: '0',
  allocation: {} as Record<string, string>,
})

const portfoliosAllocationDataValueAtom = atom<CustomPortfolioAllocationsChannelDataType | null>(
  null,
)
export const portfoliosAllocationDataAtom = atom(
  (get) => get(portfoliosAllocationDataValueAtom),
  (get, set, data: CustomPortfolioAllocationsChannelDataType) => {
    set(portfoliosAllocationDataValueAtom, data)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cash = data.allocations.find((alloc) => alloc.category === 'cash')!
    const allocation = data.allocations.filter((alloc) => alloc.category !== 'cash')

    const afterTrade = {
      cashAmount: String(cash.allocationAfterTrade.quantity),
      allocation: Object.values(allocation).reduce((acc, value) => {
        acc[String(value.id)] = String(value.allocationAfterTrade.quantity)

        return acc
      }, {} as Record<string, string>),
    }

    set(afterTradeAtom, afterTrade)

    const afterTradeDifference = {
      cashAmount: get(afterTradeDifferenceAtom).cashAmount,
      allocation: Object.values(allocation).reduce((acc, value) => {
        acc[String(value.id)] = String(
          value.allocationAfterTrade.quantity - value.currentAllocation.quantity,
        )

        return acc
      }, {} as Record<string, string>),
    }

    set(afterTradeDifferenceAtom, afterTradeDifference)
  },
)

export const tradeAtom = atom((get) => {
  const afterTradeDifference = get(afterTradeDifferenceAtom)

  const cashAmount = Number(afterTradeDifference.cashAmount)
  const allocation = mapValues(afterTradeDifference.allocation, Number)

  if (Number.isNaN(cashAmount)) {
    return undefined
  }

  if (Object.values(allocation).some(Number.isNaN)) {
    return undefined
  }

  const customOptions: CustomOptionsType = {
    cashAmount,
    allocation,
  }

  return customOptions
})
