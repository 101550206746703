import React from 'react'

import IndeterminateCheckbox from '../../../../components/IndeterminateCheckBox'
import { useInvestablePortfoliosContext } from '../InvestablePortfoliosContext'

import { selectionLimit } from './constants'

const getConditionalSelectHeaderCheckboxProps = ({ headerProps, checkIfRowIsSelectable }: any) => {
  const checkIfAllSelectableRowsSelected = (rows: any) =>
    rows.filter(checkIfRowIsSelectable).every((row: any) => row.isSelected)

  const isSelectPage = headerProps.page
    .filter(checkIfRowIsSelectable)
    .some((row: any) => !row.isSelected)

  const checkboxProps = isSelectPage
    ? headerProps.getToggleAllPageRowsSelectedProps()
    : headerProps.getToggleAllRowsSelectedProps()

  const disabled = headerProps.rows.filter(checkIfRowIsSelectable).length === 0

  const onChange = () => {
    if (!isSelectPage && checkIfAllSelectableRowsSelected(headerProps.rows)) {
      headerProps.rows.forEach((row: any) => {
        headerProps.toggleRowSelected(row.id, false)
      })
    } else {
      const rows = isSelectPage ? headerProps.page : headerProps.rows
      rows.forEach((row: any) => {
        const isChecked = checkIfRowIsSelectable(row)
        headerProps.toggleRowSelected(row.id, isChecked)
      })
    }
  }

  return {
    ...checkboxProps,
    onChange,
    disabled,
  }
}

export interface HeaderProps {
  state: {
    selectedRowIds?: any
  }
}

const Header = (props: HeaderProps) => {
  const { loading } = useInvestablePortfoliosContext()
  const {
    state: { selectedRowIds },
  } = props

  const disabledSelection = Object.keys(selectedRowIds).length >= selectionLimit

  const headerProps = getConditionalSelectHeaderCheckboxProps({
    headerProps: props,
    checkIfRowIsSelectable: ({ original: { pendingOrders, suitable } }: any) =>
      !pendingOrders && suitable,
  })

  return (
    <div>
      <IndeterminateCheckbox {...headerProps} disabled={loading || disabledSelection} />
    </div>
  )
}

export default Header
