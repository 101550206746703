import isBoolean from 'lodash/isBoolean'
import startCase from 'lodash/startCase'

import { humanizedBoolean } from '../../../../../utils'

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ cell: { value } }: any) => startCase(value),
  },
  {
    Header: 'Value',
    accessor: 'value',
    Cell: ({ cell: { value } }: any) => (isBoolean(value) ? humanizedBoolean(value) : value),
  },
]

export default columns
