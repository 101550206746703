import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import { useAtom, useSetAtom } from 'jotai'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { FormControl } from 'react-bootstrap'

import useTradePreferences from '../../../../../hooks/useTradePreferences'
import type { AllocationType, CustomOptionsType } from '../../../../../types'
import { afterTradeAtom, afterTradeDifferenceAtom } from '../../atoms'
import { usePortfoliosTradeContext } from '../../PortfoliosTradeContext'
import useAllocationValue from '../useAllocationValue'
import useFormatAllocationValue from '../useFormatAllocationValue'

export interface AfterTradeDifferenceProps {
  row: {
    original: {
      category: AllocationType['category']
      currentAllocation: Record<string, number>
      allocationAfterTrade: Record<string, number>
      id: string
      options: CustomOptionsType
    }
  }
}

const AfterTradeDifference = ({
  row: {
    original: { currentAllocation, allocationAfterTrade, category, id, options },
  },
}: AfterTradeDifferenceProps) => {
  const {
    displayType: [displayType],
  } = useTradePreferences()
  const { isGenerating, submitTrade } = usePortfoliosTradeContext()

  const currentAllocationValue = useAllocationValue(currentAllocation, false)
  const allocationAfterTradeValue = useAllocationValue(allocationAfterTrade, false)
  const differenceAfterTrade = allocationAfterTradeValue - currentAllocationValue

  const formattedDifferenceAfterTrade = useFormatAllocationValue(differenceAfterTrade)

  const [afterTradeDifference, setAfterTradeDifference] = useAtom(afterTradeDifferenceAtom)
  const setAfterTrade = useSetAtom(afterTradeAtom)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (category === 'cash') {
      setAfterTradeDifference({
        cashAmount: event.currentTarget.value,
        allocation: {},
      })
    } else {
      setAfterTradeDifference((current) => ({
        cashAmount: '',
        allocation: {
          ...current.allocation,
          [id]: event.currentTarget.value,
        },
      }))
    }

    const amount = Number(event.currentTarget.value)

    if (Number.isNaN(amount)) {
      return
    }

    if (category === 'cash') {
      setAfterTrade({
        cashAmount: String(currentAllocation.quantity + amount),
        allocation: {},
      })
    } else {
      setAfterTrade((current) => ({
        cashAmount: '',
        allocation: {
          ...current.allocation,
          [id]: String(currentAllocation.quantity + amount),
        },
      }))
    }
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitTrade()
    }
  }

  useEffect(() => {
    if (category !== 'cash') {
      setAfterTradeDifference((current) => ({
        ...current,
        allocation: {
          ...current.allocation,
          [id]: String(differenceAfterTrade),
        },
      }))
    }
  }, [category, differenceAfterTrade, id, setAfterTradeDifference])

  let variant
  if (differenceAfterTrade < 0) variant = 'danger'
  else if (differenceAfterTrade > 0) variant = 'dark-green'
  else variant = 'dark'

  if (displayType === 'quantity') {
    const input =
      category === 'cash' ? (
        numberToCurrency(differenceAfterTrade)
      ) : (
        <FormControl
          size="sm"
          disabled={isGenerating}
          value={afterTradeDifference.allocation[id] ?? '0'}
          style={{ width: '100%' }}
          className={`border border-${variant}`}
          type="number"
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
        />
      )

    const algoIcon =
      (category === 'cash' && !isEmpty(options.allocation)) ||
      (category !== 'cash' && options.cashAmount) ? (
        <span data-cy="robot-edit">🤖</span>
      ) : undefined

    const editIcon =
      (category === 'cash' && options.cashAmount) ||
      (category !== 'cash' && id in options.allocation && options.allocation[id] !== 0) ? (
        <span data-cy="user-edit">✏️</span>
      ) : undefined

    return (
      <div className="d-flex justify-content-between align-items-center" style={{ gap: '0.5rem' }}>
        {input}
        <span style={{ width: 24, height: 24 }}>{algoIcon || editIcon}</span>
      </div>
    )
  }

  if (differenceAfterTrade === 0) {
    return '-'
  }

  return <Text variant={variant}>{formattedDifferenceAfterTrade}</Text>
}

export default AfterTradeDifference
