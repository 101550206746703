import BooleanCollection from '@nord/ui/src/components/BooleanCollection'
import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import { useAtom } from 'jotai'
import React from 'react'
import { Button, ButtonGroup, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useTable } from 'react-table'

import useTradePreferences from '../../../../hooks/useTradePreferences'
import { afterTradeDifferenceAtom } from '../atoms'
import { usePortfoliosTradeContext } from '../PortfoliosTradeContext'

import columns from './columns'

export interface EtfsTableProps {
  data?: unknown[]
}

const EtfsTable = ({ data }: EtfsTableProps) => {
  const { generateAllocationData } = usePortfoliosTradeContext()
  const {
    displayType: [displayType, setDisplayType],
    optionType: [optionType, setOptionType],
  } = useTradePreferences()

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const [value, setValue] = useAtom(afterTradeDifferenceAtom)

  return (
    <Table className="mb-0" bordered striped hover responsive {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <React.Fragment key="table-row-wrapper">
            <tr {...headerGroup.getHeaderGroupProps()} key="table-row-description">
              <th colSpan={1}>
                <div className="d-flex" style={{ gap: '1rem' }}>
                  <ButtonGroup size="sm">
                    <Button
                      size="sm"
                      onClick={() => {
                        const cashAmount = Number(value.cashAmount)

                        if (!Number.isNaN(cashAmount)) {
                          generateAllocationData('buy', { cashAmount: -cashAmount, allocation: {} })
                        }
                      }}
                    >
                      Køb
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => {
                        const cashAmount = Number(value.cashAmount)

                        if (!Number.isNaN(cashAmount)) {
                          generateAllocationData('sell', { cashAmount, allocation: {} })
                        }
                      }}
                    >
                      Sælg
                    </Button>
                  </ButtonGroup>
                  <CurrencyInput
                    as={FormControl}
                    // @ts-expect-error TS(2322)
                    size="sm"
                    value={value.cashAmount}
                    onValueChange={(change) => {
                      setValue((current) => ({ ...current, cashAmount: change.toString() }))
                    }}
                  />
                </div>
              </th>
              <th colSpan={headerGroup.headers.length - 1}>
                <div className="text-center">Allokering</div>
                <div className="text-right">
                  <BooleanCollection
                    name="displayType"
                    type="radio"
                    value={displayType}
                    onValueChange={setDisplayType}
                    className="d-inline mx-1"
                    options={[
                      { value: 'percentage', label: 'Procent' },
                      { value: 'quantity', label: 'Antal' },
                      { value: 'value', label: 'Værdi' },
                    ]}
                    custom
                  />
                  {displayType === 'quantity' && (
                    <BooleanCollection
                      name="optionType"
                      type="radio"
                      value={optionType}
                      onValueChange={setOptionType}
                      className="d-inline mx-1"
                      options={[
                        { value: 'normal', label: 'Normal' },
                        { value: 'rebalance', label: 'Rebalancering' },
                        { value: 'risk_profile_change', label: 'Risikoprofilsændring' },
                      ]}
                      custom
                    />
                  )}
                </div>
              </th>
            </tr>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </thead>
      <tbody>
        {rows.map((row: any) => {
          prepareRow(row)

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

EtfsTable.defaultProps = {
  data: [],
}

export default EtfsTable
