import React from 'react'

import SelectFilter from './SelectFilter'

const booleanOptions = [
  { value: undefined, label: 'Alle' },
  { value: 'completed', label: 'completed' },
  { value: 'failed', label: 'failed' },
  { value: 'pending', label: 'pending' },
]

const StatusFilter = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ options: ({ value: undefined; label: strin... Remove this comment to see the full error message
  <SelectFilter options={booleanOptions} {...otherProps} />
)

export default StatusFilter
