import React from 'react'

import BooleanFilter from './BooleanFilter'
import SearchFilter from './SearchFilter'

export interface Props {
  columns: Column[]
  column: Column
}

const CommentFilter = ({ column, columns }: Props) => {
  const hasTradingInfoCommentColumn = columns.find((col) => col.id === 'hasTradingInfoComment')

  return (
    <div className="d-flex" style={{ gap: '1rem' }}>
      <SearchFilter column={column} />
      <BooleanFilter column={hasTradingInfoCommentColumn} />
    </div>
  )
}

export default CommentFilter

type Column = {
  filterValue?: string
  id: string
  setFilter?: (...args: any[]) => any
}
