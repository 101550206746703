import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Alert from 'react-bootstrap/Alert'

import { useInvestablePortfoliosContext } from '../InvestablePortfoliosContext'

const UpdateAlert = () => {
  const { showAlert, alertData, onHideAlert } = useInvestablePortfoliosContext()

  if (!showAlert) return null
  if (isEmpty(alertData)) return null

  const { variant, message } = alertData

  return (
    <Alert variant={variant} onClose={onHideAlert} className="mx-5 mb-3 text-center" dismissible>
      {message}
    </Alert>
  )
}

export default UpdateAlert
