import { BaffleButtonLink } from '@nord/ui/src/components/IconButtonLink'
import Text from '@nord/ui/src/components/Text'
import React from 'react'
import { Link } from 'react-router-dom'

import Page from '../../../components/Page'
import Table from '../../../components/Table'
import { useAuthPagesContext } from '../context/AuthPagesContext'

import columns from './columns'

const TradedPortfoliosPage = () => {
  const { tradedPortfolios } = useAuthPagesContext()

  return (
    <Page
      xs={12}
      md={{ offset: 1, span: 10 }}
      lg={{ offset: 2, span: 8 }}
      xl={{ offset: 3, span: 6 }}
      withCard
    >
      <Text as="h2" align="center">
        Disse porteføljer er sendt til trading
      </Text>
      <Table data={tradedPortfolios} columns={columns} />
      <div className="d-flex justify-content-center">
        <BaffleButtonLink to="/portfolios/investable" as={Link}>
          Gå tilbage til forside
        </BaffleButtonLink>
      </div>
    </Page>
  )
}

export default TradedPortfoliosPage
