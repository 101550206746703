import { useLocalStorage } from 'usehooks-ts'

export type DisplayType = 'percentage' | 'quantity' | 'value'

export type OptionType = 'normal' | 'rebalance' | 'risk_profile_change'

const useTradePreferences = () => {
  const displayType = useLocalStorage<DisplayType>('userAllocationType', 'percentage')

  const optionType = useLocalStorage<OptionType>('userOptionType', 'normal')

  return {
    displayType,
    optionType,
  }
}

export default useTradePreferences
