import type { ZodType } from 'zod'

const isProduction = process.env.NODE_ENV === 'production'

const assertZod = <Z extends ZodType<unknown>>(schema: Z, data: unknown): Z['_output'] => {
  if (isProduction) return data

  const parsed = schema.safeParse(data)
  if (parsed.success) return parsed.data

  const issues = JSON.stringify(parsed.error.issues, null, 4)
  // eslint-disable-next-line no-console
  console.error('Parse Error', '\nIssues:', issues, '\nData:', data)

  throw parsed.error
}

export default assertZod
