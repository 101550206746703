import Icon from '@nord/ui/src/components/Icon'
import Loader from '@nord/ui/src/components/Loader'
import Paginator from '@nord/ui/src/components/Paginator'
import Text from '@nord/ui/src/components/Text'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import MoreHorizontal from 'react-feather/dist/icons/more-horizontal'

import AllocationPage from '../../../components/AllocationPage'
import Page from '../../../components/Page'
import UserTable from '../../../components/UserTable'
import useRedirectWithoutParams from '../../../hooks/useRedirectWithoutParams'

import EtfsTable from './EtfsTable'
import styles from './index.module.scss'
import Navigation from './Navigation'
import { usePortfoliosTradeContext } from './PortfoliosTradeContext'

const PortfoliosTradePage = () => {
  const { acceptedPortfolioStatus, portfoliosAllocationData, onShowOverviewModal } =
    usePortfoliosTradeContext()

  useRedirectWithoutParams('portfolioIds')

  return (
    <>
      <Page xs={12}>
        {isEmpty(portfoliosAllocationData) ? (
          <Loader className="font-size-xl text-center" />
        ) : (
          // @ts-expect-error TS(2322) FIXME: Type '({ ...props }: { [x: string]: any; }) => Ele... Remove this comment to see the full error message
          <Paginator.Wrapper navigationComponent={Navigation} onComplete={onShowOverviewModal}>
            {(portfoliosAllocationData ? [portfoliosAllocationData] : []).map(
              ({ user, allocations, portfolio, options }) => {
                const isLoading = isEmpty(user) || isEmpty(portfolio) || isEmpty(allocations)

                if (isLoading)
                  return (
                    <AllocationPage key={portfolio.id}>
                      <Row>
                        <Col className={styles.loadingPage}>
                          <Loader className="font-size-xl" centeredInParent />
                        </Col>
                      </Row>
                    </AllocationPage>
                  )

                const { tradingInfoComment } = user
                const userData = { ...user, ...portfolio, portfolioId: portfolio.id }
                const etfsData = (allocations || []).map((allocation) => ({
                  ...allocation,
                  options,
                }))

                const isAccepted = acceptedPortfolioStatus[portfolio.id]

                return (
                  <AllocationPage key={portfolio.id}>
                    <Row>
                      <Col lg={3} className="d-flex flex-column justify-content-between">
                        <UserTable data={userData} />
                        {tradingInfoComment && (
                          <div className="mt-3">
                            <Text as="h6" variant="secondary">
                              Kommentar
                            </Text>
                            <Text as="div">{tradingInfoComment}</Text>
                          </div>
                        )}
                      </Col>
                      <Col lg={9}>
                        <div
                          className={classNames({
                            'border border-success': isAccepted,
                          })}
                        >
                          <EtfsTable data={etfsData} />
                        </div>
                      </Col>
                    </Row>
                  </AllocationPage>
                )
              },
            )}
          </Paginator.Wrapper>
        )}
      </Page>
      <div className={classNames(styles.overviewIcon, 'd-flex', 'justify-content-end')}>
        <Icon
          icon={MoreHorizontal}
          size="24"
          // @ts-expect-error TS(2322) FIXME: Type '{ icon: any; size: string; onClick: () => vo... Remove this comment to see the full error message
          onClick={onShowOverviewModal}
          className="text-white m-5"
        />
      </div>
    </>
  )
}

export default PortfoliosTradePage
