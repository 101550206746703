import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import { Button } from 'react-bootstrap'

import useTradePreferences from '../../../../hooks/useTradePreferences'
import { usePortfoliosTradeContext } from '../PortfoliosTradeContext'

import Allocation from './Allocation'
import useAllocationValue from './useAllocationValue'

const ChangeHeader = () => {
  const {
    displayType: [displayType],
  } = useTradePreferences()
  const { isGenerating, submitTrade } = usePortfoliosTradeContext()

  if (displayType !== 'quantity') {
    return 'Ændring'
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <span>Ændring</span>
      <Button disabled={isGenerating} size="sm" onClick={submitTrade}>
        Opdater
      </Button>
    </div>
  )
}

const columns = [
  {
    Header: 'ETFs',
    accessor: 'name',
  },
  {
    Header: 'Nuværende',
    accessor: 'currentAllocation',
    Cell: ({ cell: { value, row } }: any) => {
      const allocationValue = useAllocationValue(value, false)

      if (row.original.category === 'cash') {
        return numberToCurrency(allocationValue)
      }

      return allocationValue
    },
  },
  {
    Header: ChangeHeader,
    accessor: 'allocationAfterTradeDiff',
    Cell: Allocation.AfterTradeDifference,
  },
  {
    Header: 'Efter handel',
    accessor: 'allocationAfterTrade',
    Cell: Allocation.AfterTrade,
  },
  {
    Header: 'Ønskede',
    accessor: 'targetAllocation',
    Cell: Allocation.WithDeviationRange,
  },
]

export default columns
