import range from 'lodash/range'
import React from 'react'

import SelectFilter from './SelectFilter'

const generateRiskScoreOptions = () =>
  range(1, 21).map((number) => ({ value: number, label: String(number) }))

const defaultRiskScoreOptions = generateRiskScoreOptions()

const riskScoreOptions = [
  { value: undefined, label: 'Alle' },
  ...defaultRiskScoreOptions,
  { value: 100, label: '100' },
]

const RiskScoreFilter = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ options: ({ value: number; label: string; ... Remove this comment to see the full error message
  <SelectFilter options={riskScoreOptions} {...otherProps} />
)

export default RiskScoreFilter
