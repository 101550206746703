import Icon from '@nord/ui/src/components/Icon'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronsDown from 'react-feather/dist/icons/chevrons-down'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronsUp from 'react-feather/dist/icons/chevrons-up'

export interface Props {
  column: {
    isSorted?: boolean
    isSortedDesc?: boolean
  }
}

const SortIcon = ({ column: { isSorted, isSortedDesc } }: Props) => {
  let icon
  if (isSorted && !isSortedDesc) icon = ChevronsDown
  else if (isSortedDesc) icon = ChevronsUp

  if (!icon) return null

  return <Icon icon={icon} className="ml-1" size="18" />
}

export default SortIcon
