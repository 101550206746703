import z from 'zod'

export const Investable = z.object({
  products: z.array(z.string()),
  cashBalance: z.number(),
  correctDocumentsSignedForSimple: z.boolean(),
  depotType: z.string(),
  email: z.string(),
  esg: z.boolean(),
  investable: z.boolean(),
  invested: z.boolean(),
  lastDeposit: z.number().nullable(),
  lastDepositDate: z.string().nullable(),
  orderHandlingAccepted: z.boolean(),
  pendingOrders: z.boolean(),
  portfolioId: z.number(),
  riskScore: z.number(),
  saxoBankAccountId: z.string(),
  suitable: z.boolean(),
  tradingInfoComment: z.string().nullable(),
  userFreeAssetsPortfoliosTotalCashBalance: z.number().nullable(),
  userHasFreeAssetsPortfolioInOverdraft: z.boolean().nullable(),
  userId: z.number(),
})

export type InvestableType = z.infer<typeof Investable>
