import React, { forwardRef, useEffect, useRef } from 'react'
import FormCheck from 'react-bootstrap/FormCheck'

export interface Props {
  indeterminate: boolean
}

const IndeterminateCheckbox = forwardRef<any, Props>(({ indeterminate, ...otherProps }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'current' does not exist on type '((insta... Remove this comment to see the full error message
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <FormCheck type="checkbox" ref={resolvedRef} {...otherProps} />
})

export default IndeterminateCheckbox
