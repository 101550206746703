const columns = [
  {
    Header: 'Account key',
    accessor: 'accountKey',
  },
  {
    Header: 'Priority',
    accessor: 'priority',
  },
  {
    Header: 'Unit value',
    accessor: 'unitValue',
  },
  {
    Header: 'Accept remainder amount',
    accessor: 'acceptRemainderAmount',
    Cell: ({ cell: { value } }: any) => (value ? '✅' : '⛔️'),
  },
]

export default columns
