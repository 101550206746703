import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useAppContext } from '../../context/AppContext'

import LoadingPage from './LoadingPage'

const UnauthPages = () => {
  const { isSigningIn, isSignedIn } = useAppContext()

  if (isSigningIn) {
    return <LoadingPage />
  }

  return isSignedIn ? <Navigate to="/portfolios/investable" replace /> : <Outlet />
}

export default UnauthPages
