import z from 'zod'

export const User = z.object({
  email: z.string(),
  tradingInfoComment: z.string().nullable(),
  userFreeAssetsPortfoliosTotalCashBalance: z.number(),
  userHasFreeAssetsPortfolioInOverdraft: z.boolean().nullable(),
})

export type UserType = z.infer<typeof User>
