import { useApi } from '@nord/ui/src/hooks'
import { useApiRequest } from '@nord/ui/src/hooks/useApi'
import constate from 'constate'
import { useMemo, useState } from 'react'

import useOrdersPreferences from './useOrdersPreferences'

const useOrdersPage = () => {
  const {
    filters: [tableFilters],
  } = useOrdersPreferences()

  const [apiFilter, setApiFilter] = useState(() =>
    tableFilters.reduce((acc, filter) => {
      acc[filter.id] = filter.value

      return acc
    }, {} as Record<string, string>),
  )

  const [showOrderModal, setShowOrderModal] = useState(false)
  const [currentTradeGroupId, setCurrentTradeGroupId] = useState<number>()
  const [currentOrderId, setCurrentOrderId] = useState<number>()

  const handleShowOrderModal = ({
    orderId,
    tradeGroupId,
  }: {
    orderId: number
    tradeGroupId: number
  }) => {
    setShowOrderModal(true)
    setCurrentOrderId(orderId)
    setCurrentTradeGroupId(tradeGroupId)
  }

  const handleHideOrderModal = () => {
    setShowOrderModal(false)
    setCurrentOrderId(undefined)
  }

  const fetchOrders = useApi('/limited_admin/etf_trading/orders', {
    withCredentials: true,
  })

  const {
    data,
    loading,
    request: getOrders,
  } = useApiRequest(fetchOrders, {
    enableDebounce: true,
    debounceTimeout: 500,
  })

  const { data: ordersData, meta } = data || {}
  const { pagination } = meta || {}
  const { last: pageCount } = pagination || {}

  const currentOrder = useMemo(() => {
    if (!ordersData || !currentOrderId || !currentTradeGroupId) {
      return null
    }

    const tradeGroup = ordersData.find((entry: any) => entry.id === currentTradeGroupId)
    const order = tradeGroup.saxoEtfOrders.find((entry: any) => entry.id === currentOrderId)

    return order
  }, [currentOrderId, currentTradeGroupId, ordersData])

  return {
    apiFilter,
    currentOrderId,
    currentOrder,
    loading,
    ordersData,
    pageCount,
    showOrderModal,
    getOrders,
    onHideOrderModal: handleHideOrderModal,
    onShowOrderModal: handleShowOrderModal,
    setApiFilter,
  }
}

export const [OrdersPageProvider, useOrdersPageContext] = constate(useOrdersPage)
