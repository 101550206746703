import React from 'react'

import SelectFilter from './SelectFilter'

const depotTypeOptions = [
  { value: undefined, label: 'Alle' },
  { value: 'frie_midler', label: 'Frie midler' },
  { value: 'ratepension', label: 'Ratepension' },
  { value: 'kapitalpension', label: 'Kapitalpension' },
  { value: 'aldersopsparing', label: 'Aldersopsparing' },
]

const DepotTypeFilter = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ options: ({ value: undefined; label: strin... Remove this comment to see the full error message
  <SelectFilter options={depotTypeOptions} {...otherProps} />
)

export default DepotTypeFilter
