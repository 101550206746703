import useTradePreferences from '../../../../hooks/useTradePreferences'

import useFormatAllocationValue from './useFormatAllocationValue'

const useAllocationValue = ((displayValues: Record<string, number>, formatValue = true) => {
  const {
    displayType: [displayType],
  } = useTradePreferences()

  const value = displayValues[displayType]
  const formattedValue = useFormatAllocationValue(value)

  if (!formatValue) return value

  return formattedValue
}) as {
  (displayValues: Record<string, number>, formatValue: false): number
  (displayValues: Record<string, number>, formatValue?: true): string
}

export default useAllocationValue
