import constate from 'constate'
import { useState } from 'react'

const useAuthPages = () => {
  const [tradedPortfolios, setTradedPortfolios] = useState<Record<string, unknown>[]>([])

  return {
    tradedPortfolios,
    setTradedPortfolios,
  }
}

export const [AuthPagesProvider, useAuthPagesContext] = constate(useAuthPages)
