import Text from '@nord/ui/src/components/Text'
import React from 'react'

import styles from './NoResultsMessage.module.scss'

export interface Props {
  loading: boolean
}

const NoResultsMessage = ({ loading }: Props) => {
  if (loading) return null

  return (
    <tr>
      <td>
        <Text as="div" size="lg" variant="dark" className={styles.noResultsWrapper}>
          Ingen resultater fundet
        </Text>
      </td>
    </tr>
  )
}

export default NoResultsMessage
