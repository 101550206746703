const columns = [
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Konto ID',
    accessor: 'saxoBankAccountId',
  },
]

export default columns
