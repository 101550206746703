import BooleanCollection from '@nord/ui/src/components/BooleanCollection'
import React from 'react'
import Table from 'react-bootstrap/Table'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useTable } from 'react-table'

import { usePortfoliosTradesContext } from '../PortfoliosTradesContext'

import columns from './columns'

export interface EtfsTableProps {
  data?: unknown[]
}

const EtfsTable = ({ data }: EtfsTableProps) => {
  const { displayType, onDisplayTypeChange } = usePortfoliosTradesContext()

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <Table className="mb-0" bordered striped hover responsive {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <React.Fragment key="table-row-wrapper">
            <tr {...headerGroup.getHeaderGroupProps()} key="table-row-description">
              <th colSpan={1}> </th>
              <th colSpan={headerGroup.headers.length - 1}>
                <div className="text-center">Allokering</div>
                <div className="text-right">
                  <BooleanCollection
                    name="displayType"
                    type="radio"
                    value={displayType}
                    onValueChange={onDisplayTypeChange}
                    className="d-inline mx-1"
                    options={[
                      { value: 'percentage', label: 'Procent' },
                      { value: 'quantity', label: 'Antal' },
                      { value: 'value', label: 'Værdi' },
                    ]}
                    custom
                  />
                </div>
              </th>
            </tr>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </thead>
      <tbody>
        {rows.map((row: any) => {
          prepareRow(row)

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

EtfsTable.defaultProps = {
  data: [],
}

export default EtfsTable
