import React from 'react'

import styles from './TableBody.module.scss'

export interface Props {
  children: React.ReactNode
}

const TableBody = ({ children, ...otherProps }: Props) => (
  <tbody {...otherProps} className={styles.tableBody}>
    {children}
  </tbody>
)

export default TableBody
