import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useAppContext } from '../../context/AppContext'
import LoadingPage from '../UnauthPages/LoadingPage'

import { AuthPagesProvider } from './context/AuthPagesContext'

const AuthPages = () => {
  const { isSigningIn, isSignedIn } = useAppContext()

  if (isSigningIn) {
    return <LoadingPage />
  }

  return isSignedIn ? (
    <AuthPagesProvider>
      <Outlet />
    </AuthPagesProvider>
  ) : (
    <Navigate to="/brugere/logind" replace />
  )
}

export default AuthPages
