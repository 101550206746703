import React from 'react'

import MultiSelectFilter from './MultiSelectFilter'
import SelectFilter from './SelectFilter'

const productOptions = [
  { value: undefined, label: 'Alle' },
  { value: 'free_assets', label: 'Standard' },
  { value: 'esg', label: 'ESG' },
  { value: 'simple', label: 'Simple' },
  { value: 'with_properties', label: 'Ejendomme' },
  { value: 'pension', label: 'Pension' },
  { value: 'pension_payment', label: 'Pensions udbetaling' },
]

const ProductFilter = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ options: ({ value: undefined; label: strin... Remove this comment to see the full error message
  <MultiSelectFilter options={productOptions} {...otherProps} />
)

export default ProductFilter
