import React from 'react'

import SelectFilter from './SelectFilter'

const booleanOptions = [
  { value: undefined, label: 'Alle' },
  { value: 'buy', label: 'buy' },
  { value: 'sell', label: 'sell' },
  { value: 'rebalance', label: 'rebalance' },
  { value: 'risk_profile_change', label: 'risk profile change' },
  { value: 'custom', label: 'custom' },
]

const TradeTypeFilter = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ options: ({ value: undefined; label: strin... Remove this comment to see the full error message
  <SelectFilter options={booleanOptions} {...otherProps} />
)

export default TradeTypeFilter
