import formatDate from '@nord/ui/src/utilities/formatDate'
import { isToday, parseISO } from 'date-fns'

export type DateValue = string | Date

export const isDateValue = (value: unknown): value is DateValue => {
  if (value instanceof Date) {
    return true
  }

  if (typeof value === 'string' && !Number.isNaN(parseISO(value).getTime())) {
    return true
  }

  return false
}

const humanizedDate = (value?: DateValue | null) => {
  if (value == null) {
    return 'N/A'
  }

  const date = value instanceof Date ? value : parseISO(value)
  const isTodayEmoji = isToday(date) ? '✅' : '⛔️'

  return `${formatDate(value)} (i dag: ${isTodayEmoji})`
}

export default humanizedDate
