import Loader from '@nord/ui/src/components/Loader'
import classNames from 'classnames'
import React from 'react'

import styles from './Wrapper.module.scss'

export interface Props {
  children: React.ReactNode
  loading: boolean
}

const Wrapper = ({ children, loading }: Props) => (
  <>
    {loading && (
      <Loader
        className={classNames('font-size-xl text-dark', {
          [styles.loader]: loading,
        })}
        centeredInParent
      />
    )}
    <div
      className={classNames('table-responsive', styles.tableWrapper, {
        [styles.tableOverlay]: loading,
      })}
    >
      {children}
    </div>
  </>
)

export default Wrapper
