import Loader from '@nord/ui/src/components/Loader'
import React from 'react'

import Page from '../../../components/Page'

const LoadingPage = () => (
  <Page
    xs={12}
    md={{ offset: 1, span: 10 }}
    lg={{ offset: 2, span: 8 }}
    xl={{ offset: 3, span: 6 }}
  >
    <Loader className="font-size-xl" centeredInParent />
  </Page>
)

export default LoadingPage
