import React from 'react'

import StyledReactSelect from '../StyledReactSelect'

import styles from './index.module.scss'

const selectStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 300,
  }),
}

const Menu = (props: any) => <div className={styles.menu} {...props} />

const Blanket = (props: any) => <div className={styles.blanket} {...props} />

export interface DropdownProps {
  className: string
  children: React.ReactNode
  isOpen: boolean
  onClose: (...args: any[]) => any
  target: React.ReactNode
}

const Dropdown = ({ className, children, isOpen, target, onClose }: DropdownProps) => (
  <div className={className} style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

export interface MultiSelectProps {
  children: React.ReactNode
  isOpen: boolean
  onChange: (...args: any[]) => any
  onToggle: (...args: any[]) => any
  options: Value[]
  values: Value[]
  placeholder?: string
}

const MultiSelect = ({
  isOpen,
  onToggle,
  options,
  values,
  onChange,
  children,
  placeholder,
}: MultiSelectProps) => (
  <Dropdown className="ml-0" isOpen={isOpen} onClose={onToggle} target={children}>
    <StyledReactSelect
      // @ts-expect-error TS(2322) FIXME: Type '{ backspaceRemovesValue: boolean; components... Remove this comment to see the full error message
      backspaceRemovesValue={false}
      components={{
        IndicatorSeparator: null,
      }}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      closeMenuOnSelect={false}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={selectStyles}
      tabSelectsValue={false}
      value={values}
      autoFocus
      menuIsOpen
      isMulti
    />
  </Dropdown>
)

type Value = {
  value: string
  label: string
}

MultiSelect.defaultProps = {
  placeholder: 'Select',
}

export default MultiSelect
