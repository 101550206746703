import BooleanFilter from './BooleanFilter'
import CommentFilter from './CommentFilter'
import DepotTypeFilter from './DepotTypeFilter'
import ProductFilter from './ProductFilter'
import RiskScoreFilter from './RiskScoreFilter'
import SearchFilter from './SearchFilter'
import SimpleDocumentsFilter from './SimpleDocumentsFilter'

const Filter = {
  Boolean: BooleanFilter,
  Comment: CommentFilter,
  DepotType: DepotTypeFilter,
  Product: ProductFilter,
  RiskScore: RiskScoreFilter,
  Search: SearchFilter,
  SimpleDocuments: SimpleDocumentsFilter,
}

export default Filter
