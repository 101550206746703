import BooleanFilter from './BooleanFilter'
import SearchFilter from './SearchFilter'
import StateFilter from './StateFilter'

const Filter = {
  Boolean: BooleanFilter,
  State: StateFilter,
  Search: SearchFilter,
}

export default Filter
