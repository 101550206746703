import SearchFilter from './SearchFilter'
import StatusFilter from './StatusFilter'
import TradeTypeFilter from './TradeTypeFilter'

const Filter = {
  TradeType: TradeTypeFilter,
  Search: SearchFilter,
  Status: StatusFilter,
}

export default Filter
