import { useLocalStorage } from 'usehooks-ts'

const useSaxoHouseTransferPreferences = () => {
  const filters = useLocalStorage<{ id: string; value: string | string[] }[]>(
    'saxoHouseTransferFilters',
    [{ id: 'saxoHouseTransferState', value: ['saxo_house_transfer_documents_signed', 'saxo_house_transfer_pending'] }]
  )

  const sort = useLocalStorage('saxoHouseTransferSort', { id: 'desc' })
  const sortBy = Object.entries(sort[0]).map(([id, user]) => ({ [user]: true, id }))

  return { filters, sort, sortBy }
}

export default useSaxoHouseTransferPreferences
