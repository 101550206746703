import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import constate from 'constate'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import type { UserType } from '../types/User'

const useApp = () => {
  const [currentUser, setCurrentUser] = useState<UserType>()

  const history = useHistory()

  const fetchCurrentUser = useApi('/admin_users/current', {
    withCredentials: true,
    errorHandling: {
      ignore: {
        unauthorized: true,
      },
    },
  })

  const { data, success, loading } = useApiRequest(fetchCurrentUser, {
    autoCall: true,
  })

  useEffect(() => {
    if (!success) return
    if (!data) return

    setCurrentUser(data)
  }, [data, history, success, setCurrentUser])

  const isSignedIn = !isEmpty(currentUser)

  return {
    currentUser,
    isSignedIn,
    isSigningIn: loading,
    setCurrentUser,
  }
}

export const [AppProvider, useAppContext] = constate(useApp)
