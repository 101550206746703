import Text from '@nord/ui/src/components/Text'
import mapValues from 'lodash/mapValues'
import toFinite from 'lodash/toFinite'
import toNumber from 'lodash/toNumber'
import React from 'react'

import useAllocationValue from '../useAllocationValue'

const inRange = (number: any, start: any, end: any) => {
  let formattedStart = toFinite(start)
  let formattedEnd
  const formattedNumber = toNumber(number)

  if (end === undefined) {
    formattedEnd = start
    formattedStart = 0
  } else {
    formattedEnd = toFinite(end)
  }

  const isInRange =
    formattedNumber >= Math.min(formattedStart, formattedEnd) &&
    number <= Math.max(formattedStart, formattedEnd)

  return isInRange
}

const useAllocationAfterTradeDiffValue = (allocationAfterTradeDiff: any) => {
  const formattedAllocationAfterTradeDiff = mapValues(allocationAfterTradeDiff, (value) => {
    let formattedValue

    if (value > 0) formattedValue = -Math.abs(value)
    else if (value < 0) formattedValue = Math.abs(value)
    else formattedValue = value

    return formattedValue
  })

  const formattedAllocationAfterTradeDiffValue = useAllocationValue(
    formattedAllocationAfterTradeDiff,
  )

  const allocationAfterTradeDiffValue = useAllocationValue(formattedAllocationAfterTradeDiff, false)

  const isPositive = allocationAfterTradeDiffValue > 0

  if (isPositive) return `+${formattedAllocationAfterTradeDiffValue}`

  return formattedAllocationAfterTradeDiffValue
}

export interface AfterTradeProps {
  cell: any // TODO: PropTypes.shape()
  row: any // TODO: PropTypes.shape()
}

const AfterTrade = ({
  cell: { value },
  row: {
    original: { allocationAfterTradeDiff, targetAllocationMin, targetAllocationMax },
    values: { name },
  },
}: AfterTradeProps) => {
  const formattedAllocationAfterTradeValue = useAllocationValue(value)
  const formattedAllocationAfterTradeDiffValue =
    useAllocationAfterTradeDiffValue(allocationAfterTradeDiff)

  const allocationAfterTradeValue = useAllocationValue(value, false)
  const targetAllocationMinValue = useAllocationValue(targetAllocationMin, false)
  const targetAllocationMaxValue = useAllocationValue(targetAllocationMax, false)

  if (name === 'CASH') return formattedAllocationAfterTradeValue

  const variant = inRange(
    allocationAfterTradeValue,
    targetAllocationMinValue,
    targetAllocationMaxValue,
  )
    ? 'dark'
    : 'danger'

  return (
    <>
      <Text variant={variant}>{formattedAllocationAfterTradeValue}</Text>{' '}
      <Text variant="primary">({formattedAllocationAfterTradeDiffValue})</Text>
    </>
  )
}

export default AfterTrade
