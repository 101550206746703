import Text from '@nord/ui/src/components/Text'
import {
  numbersToPercentRange,
  numberToCurrency,
  numberToString,
} from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

import { usePortfoliosTradesContext } from '../../PortfoliosTradesContext'
import useAllocationValue from '../useAllocationValue'

const useAllocationRange = (
  minDisplayValues: Record<string, number>,
  maxDisplayValues: Record<string, number>,
) => {
  const { displayType } = usePortfoliosTradesContext()

  const minValue = minDisplayValues[displayType]
  const maxValue = maxDisplayValues[displayType]

  switch (displayType) {
    case 'percentage':
      return numbersToPercentRange(minValue / 100, maxValue / 100)
    case 'value':
      return `${numberToString(minValue)} - ${numberToCurrency(maxValue)}`
    default:
      return `${minValue} - ${maxValue}`
  }
}

export interface WithDeviationRangeProps {
  cell: { value: Record<string, number> }
  row: {
    original: {
      targetAllocationMin: Record<string, number>
      targetAllocationMax: Record<string, number>
    }
    values: { name: string }
  }
}

export const WithDeviationRange = ({
  cell: { value },
  row: {
    original: { targetAllocationMin, targetAllocationMax },
    values: { name },
  },
}: WithDeviationRangeProps) => {
  const allocationWithDeviationValue = useAllocationValue(value)

  const allocationWithDeviationRange = useAllocationRange(targetAllocationMin, targetAllocationMax)

  if (name === 'CASH') return allocationWithDeviationValue

  return (
    <>
      {allocationWithDeviationValue} <Text variant="primary">({allocationWithDeviationRange})</Text>
    </>
  )
}

export default WithDeviationRange
