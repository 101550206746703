import { useLocalStorage } from 'usehooks-ts'

const useInvestablesPreferences = () => {
  const filters = useLocalStorage('investablesFilters', [{ id: 'investable', value: 'true' }])

  const hidden = useLocalStorage('investablesHidden', [])

  const widths = useLocalStorage('investablesWidths', {
    selection: 50,
    email: 250,
    tradingInfoComment: 350,
    lastDepositDate: 200,
  })

  const sort = useLocalStorage('investablesSort', { lastDepositDate: 'desc' })
  const sortBy = Object.entries(sort[0]).map(([id, order]) => ({ [order]: true, id }))

  return { filters, hidden, widths, sort, sortBy }
}

export default useInvestablesPreferences
