import times from 'lodash/times'
import React from 'react'
import BootstrapPagination from 'react-bootstrap/Pagination'

import Wrapper from './Wrapper'

export const generatePages = (currentPageIndex: number, pageCount: number, pagesAround = 2) => {
  const pageIndexes = times(pageCount, Number)

  return pageIndexes.reduce((pages, pageIndex) => {
    // Add first and last page
    if (pageIndex === 0 || pageIndex === pageCount - 1) return [...pages, pageIndex]

    // Add if pageIndex is within pagesRange
    if (pageIndex - pagesAround <= currentPageIndex && pageIndex + pagesAround >= currentPageIndex)
      return [...pages, pageIndex]

    // Otherwise add ... if it was not added already
    if (pages[pages.length - 1] !== '...') return [...pages, '...']

    return pages
  }, [] as (string | number)[])
}

export interface PaginationProps {
  canNextPage: boolean
  canPreviousPage: boolean
  pageCount: number
  pageIndex: number
  pageSize: number
  previousPage(): void
  gotoPage(page: number): void
  nextPage(): void
  className?: string
}

const Pagination = ({
  canNextPage,
  canPreviousPage,
  pageCount,
  pageIndex,
  pageSize,
  previousPage,
  gotoPage,
  nextPage,
  ...otherProps
}: PaginationProps) => {
  const pages = generatePages(pageIndex, pageCount)

  return (
    <BootstrapPagination className="my-5" {...otherProps}>
      <BootstrapPagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
      <BootstrapPagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
      {pages.map((paginationItemIndex, index) => {
        if (typeof paginationItemIndex === 'string') {
          const elipisisKey = `ellipsis-${index === 1 ? 'start' : 'end'}`

          return <BootstrapPagination.Ellipsis key={elipisisKey} disabled />
        }

        return (
          <BootstrapPagination.Item
            key={`page-${paginationItemIndex}`}
            active={paginationItemIndex === pageIndex}
            onClick={() => gotoPage(paginationItemIndex)}
          >
            {paginationItemIndex + 1}
          </BootstrapPagination.Item>
        )
      })}
      <BootstrapPagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
      <BootstrapPagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
    </BootstrapPagination>
  )
}

Pagination.defaultProps = {
  className: '',
}

Pagination.Wrapper = Wrapper

export default Pagination
