import Text from '@nord/ui/src/components/Text'
import React from 'react'
import Card from 'react-bootstrap/Card'

import Page from '../../../components/Page'

import { useOrdersPageContext } from './OrdersPageContext'
import OrdersTable from './OrdersTable'

const OrdersPage = () => {
  const { ordersData } = useOrdersPageContext()

  return (
    <Page xs={12}>
      <Card>
        <div className="m-5 d-flex align-items-center justify-content-center">
          <Text as="h2" variant="dark" align="center">
            Ordrer
          </Text>
        </div>
        <OrdersTable data={ordersData} />
      </Card>
    </Page>
  )
}

export default OrdersPage
