import z from 'zod'

const BaseAllocation = z.object({
  name: z.string(),
  danishName: z.string(),
  currentAllocation: z.object({
    percentage: z.number(),
    quantity: z.number(),
    value: z.number(),
  }),
  targetAllocationMin: z.object({
    percentage: z.number(),
    quantity: z.number(),
    value: z.number(),
  }),
  targetAllocationMax: z.object({
    percentage: z.number(),
    quantity: z.number(),
    value: z.number(),
  }),
  targetAllocation: z.object({
    percentage: z.number(),
    quantity: z.number(),
    value: z.number(),
  }),
  allocationAfterTrade: z.object({
    percentage: z.number(),
    quantity: z.number(),
    value: z.number(),
  }),
  allocationAfterTradeDiff: z.object({
    percentage: z.number(),
    quantity: z.number(),
    value: z.number(),
  }),
})

const Cash = BaseAllocation.extend({
  category: z.literal('cash'),
  id: z.null(),
})

const NonCash = BaseAllocation.extend({
  category: z.enum(['stocks', 'bonds', 'corporate_bonds', 'government_bonds', 'properties']),
  id: z.number(),
})

export const Allocation = z.union([Cash, NonCash])

export type AllocationType = z.infer<typeof Allocation>
