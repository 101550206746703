import ApplicationWrapper from '@nord/ui/src/components/ApplicationWrapper'
import EnvironmentBar from '@nord/ui/src/components/ApplicationWrapper/plugins/EnvironmentBar'
import ErrorBoundary from '@nord/ui/src/components/ApplicationWrapper/plugins/ErrorBoundary'
import PageLayout from '@nord/ui/src/components/ApplicationWrapper/plugins/PageLayout'
import React from 'react'
import { Navigate, createBrowserRouter, RouterProvider, type RouteObject } from 'react-router-dom'

import PagesWrapper from './components/PagesWrapper'
import { AppProvider } from './context/AppContext'
import AuthPages from './pages/AuthPages'
import InvestablePortfoliosPage from './pages/AuthPages/InvestablePortfoliosPage'
import { InvestablePortfoliosProvider } from './pages/AuthPages/InvestablePortfoliosPage/InvestablePortfoliosContext'
import OrdersPage from './pages/AuthPages/OrdersPage'
import { OrdersPageProvider } from './pages/AuthPages/OrdersPage/OrdersPageContext'
import PortfoliosTradePage from './pages/AuthPages/PortfoliosTradePage'
import { PortfoliosTradeProvider } from './pages/AuthPages/PortfoliosTradePage/PortfoliosTradeContext'
import PortfoliosTradesPage from './pages/AuthPages/PortfoliosTradesPage'
import { PortfoliosTradesProvider } from './pages/AuthPages/PortfoliosTradesPage/PortfoliosTradesContext'
import SaxoHouseTransfersPage from './pages/AuthPages/SaxoHouseTransfersPage'
import { UsersProvider } from './pages/AuthPages/SaxoHouseTransfersPage/UsersContext'
import TradedPortfoliosPage from './pages/AuthPages/TradedPortfoliosPage'
import UsersIndexPage from './pages/AuthPages/UsersIndexPage'
import UsersShowPage, { loader as usersShowLoader } from './pages/AuthPages/UsersShowPage'
import UnauthPages from './pages/UnauthPages'
import LoginPage from './pages/UnauthPages/LoginPage'

const unauth: RouteObject = {
  element: <UnauthPages />,
  children: [
    {
      path: '/brugere/logind',
      element: <LoginPage />,
    },
    {
      path: '*',
      element: <Navigate to="/brugere/logind" replace />,
    },
  ],
}

const auth: RouteObject = {
  element: <AuthPages />,
  children: [
    {
      path: '/portfolios/investable',
      element: (
        <InvestablePortfoliosProvider>
          <InvestablePortfoliosPage />
        </InvestablePortfoliosProvider>
      ),
    },
    {
      path: '/portfolios/trade',
      element: (
        <PortfoliosTradeProvider>
          <PortfoliosTradePage />
        </PortfoliosTradeProvider>
      ),
    },
    {
      path: '/portfolios/trades',
      element: (
        <PortfoliosTradesProvider>
          <PortfoliosTradesPage />
        </PortfoliosTradesProvider>
      ),
    },
    {
      path: '/portfolios/traded',
      element: <TradedPortfoliosPage />,
    },
    {
      path: '/orders',
      element: (
        <OrdersPageProvider>
          <OrdersPage />
        </OrdersPageProvider>
      ),
    },
    {
      path: '/users',
      element: <UsersIndexPage />,
      children: [
        {
          path: '/users/:id',
          loader: usersShowLoader,
          element: <UsersShowPage />,
        },
      ],
    },
    {
      path: '/saxo-house-transfers',
      element: (
        <UsersProvider>
          <SaxoHouseTransfersPage />
        </UsersProvider>
      ),
    },
    {
      path: '*',
      element: <Navigate to="/portfolios/investable" replace />,
    },
  ],
}

const router = createBrowserRouter([
  {
    element: <PagesWrapper />,
    children: [unauth, auth],
  },
])

const App = () => (
  <AppProvider>
    {/* @ts-expect-error TS(2786) FIXME: 'ApplicationWrapper' cannot be used as a JSX compo... Remove this comment to see the full error message */}
    <ApplicationWrapper
      // @ts-expect-error TS(2322) FIXME: Type '({ children }: ErrorBoundaryProps) => JSX.El... Remove this comment to see the full error message
      plugins={[ErrorBoundary, EnvironmentBar, PageLayout]}
      pluginsProps={{
        PageLayout: {
          className: 'px-0',
          fluid: true,
        },
      }}
    >
      <RouterProvider router={router} />
    </ApplicationWrapper>
  </AppProvider>
)

export default App
