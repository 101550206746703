import isEmpty from 'lodash/isEmpty'

import useCurrentUser from './useCurrentUser'

const useIsSignedIn = () => {
  const currentUser = useCurrentUser()

  const isSignedIn = !isEmpty(currentUser)

  return isSignedIn
}

export default useIsSignedIn
