import React from 'react'

import type { UserType } from '../../types'
import Table from '../Table'

import columns from './columns'
import formatData from './formatData'

export interface UserTableProps {
  data: UserType
}

const UserTable = ({ data }: UserTableProps) => {
  const dataForTable = formatData(data) || []

  return <Table data={dataForTable} columns={columns} hide={['Portefølje ID']} />
}

export default UserTable
