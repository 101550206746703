import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import { useAtom, useSetAtom } from 'jotai'
import mapValues from 'lodash/mapValues'
import toFinite from 'lodash/toFinite'
import toNumber from 'lodash/toNumber'
import React from 'react'
import { FormControl } from 'react-bootstrap'

import useTradePreferences from '../../../../../hooks/useTradePreferences'
import { afterTradeAtom, afterTradeDifferenceAtom } from '../../atoms'
import { usePortfoliosTradeContext } from '../../PortfoliosTradeContext'
import useAllocationValue from '../useAllocationValue'

const inRange = (number: any, start: any, end: any) => {
  let formattedStart = toFinite(start)
  let formattedEnd
  const formattedNumber = toNumber(number)

  if (end === undefined) {
    formattedEnd = start
    formattedStart = 0
  } else {
    formattedEnd = toFinite(end)
  }

  const isInRange =
    formattedNumber >= Math.min(formattedStart, formattedEnd) &&
    number <= Math.max(formattedStart, formattedEnd)

  return isInRange
}

const useAllocationAfterTradeDiffValue = (allocationAfterTradeDiff: any) => {
  const formattedAllocationAfterTradeDiff = mapValues(allocationAfterTradeDiff, (value) => {
    let formattedValue

    if (value > 0) formattedValue = -Math.abs(value)
    else if (value < 0) formattedValue = Math.abs(value)
    else formattedValue = value

    return formattedValue
  })

  const formattedAllocationAfterTradeDiffValue = useAllocationValue(
    formattedAllocationAfterTradeDiff,
  )

  const allocationAfterTradeDiffValue = useAllocationValue(formattedAllocationAfterTradeDiff, false)

  const isPositive = allocationAfterTradeDiffValue > 0

  if (isPositive) return `+${formattedAllocationAfterTradeDiffValue}`

  return formattedAllocationAfterTradeDiffValue
}

export interface AfterTradeProps {
  cell: any // TODO: PropTypes.shape()
  row: any // TODO: PropTypes.shape()
}

const AfterTrade = ({
  cell: { value },
  row: {
    original: {
      currentAllocation,
      allocationAfterTradeDiff,
      targetAllocationMin,
      targetAllocationMax,
      category,
      id,
    },
    values: { name },
  },
}: AfterTradeProps) => {
  const { submitTrade, isGenerating } = usePortfoliosTradeContext()

  const {
    displayType: [displayType],
  } = useTradePreferences()

  const formattedAllocationAfterTradeValue = useAllocationValue(value)
  const formattedAllocationAfterTradeDiffValue =
    useAllocationAfterTradeDiffValue(allocationAfterTradeDiff)

  const allocationAfterTradeValue = useAllocationValue(value, false)
  const targetAllocationMinValue = useAllocationValue(targetAllocationMin, false)
  const targetAllocationMaxValue = useAllocationValue(targetAllocationMax, false)

  const [afterTrade, setAfterTrade] = useAtom(afterTradeAtom)
  const setAfterTradeDifference = useSetAtom(afterTradeDifferenceAtom)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (category === 'cash') {
      setAfterTrade({
        cashAmount: event.currentTarget.value,
        allocation: {},
      })
    } else {
      setAfterTrade((current) => ({
        cashAmount: '',
        allocation: {
          ...current.allocation,
          [id]: event.currentTarget.value,
        },
      }))
    }

    const amount = Number(event.currentTarget.value)

    if (Number.isNaN(amount)) {
      return
    }

    if (category === 'cash') {
      setAfterTradeDifference((current) => ({
        ...current,
        cashAmount: String(amount - currentAllocation.quantity),
        allocation: {},
      }))
    } else {
      setAfterTradeDifference((current) => ({
        ...current,
        cashAmount: '',
        allocation: {
          ...current.allocation,
          [id]: String(amount - currentAllocation.quantity),
        },
      }))
    }
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitTrade()
    }
  }

  const variant = inRange(
    allocationAfterTradeValue,
    targetAllocationMinValue,
    targetAllocationMaxValue,
  )
    ? 'dark'
    : 'danger'

  if (displayType === 'quantity') {
    return (
      <div className="d-flex justify-content-between align-items-center" style={{ gap: '0.5rem' }}>
        {category === 'cash' ? (
          numberToCurrency(formattedAllocationAfterTradeValue)
        ) : (
          <FormControl
            size="sm"
            style={{ width: '12rem' }}
            disabled={isGenerating}
            type="number"
            className={`border border-${variant}`}
            value={afterTrade.allocation[id] ?? '0'}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
          />
        )}
        <Text variant="primary"> ({formattedAllocationAfterTradeDiffValue})</Text>
      </div>
    )
  }

  if (name === 'CASH') return formattedAllocationAfterTradeValue

  return (
    <>
      <Text variant={variant}>{formattedAllocationAfterTradeValue}</Text>{' '}
      <Text variant="primary">({formattedAllocationAfterTradeDiffValue})</Text>
    </>
  )
}

export default AfterTrade
