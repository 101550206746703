import omit from 'lodash/omit'
import React from 'react'
import FormControl from 'react-bootstrap/FormControl'

import { useUsersContext } from '../UsersContext'

export interface Props {
  column: {
    filterValue?: string
    id: string
    setFilter?: (...args: any[]) => any
  }
}

const SearchFilter = ({ column: { id, filterValue, setFilter } }: Props) => {
  const { apiFilter, setApiFilter } = useUsersContext()

  const handleSearch = ({ target: { value } }: any) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setFilter(value)

    if (value === '') setApiFilter(omit(apiFilter, [id]))
    else setApiFilter({ ...apiFilter, [id]: value })
  }

  const searchValue = apiFilter[id] || filterValue || ''

  return (
    <FormControl
      onChange={handleSearch}
      value={searchValue}
      placeholder="Søg..."
      className="mt-3"
      data-cy={`${id}-search-filter`}
    />
  )
}

export default SearchFilter
