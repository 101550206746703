import useQuery from '@nord/ui/src/hooks/useQuery'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const useRedirectWithoutParams = (params: string) => {
  const navigate = useNavigate()

  const currentPortfolioIdsParam = useQuery(params)

  useEffect(() => {
    if (currentPortfolioIdsParam) return

    navigate('/portfolios/investable')
  }, [currentPortfolioIdsParam, navigate])
}

export default useRedirectWithoutParams
