import React from 'react'

import SelectFilter from './SelectFilter'

const booleanOptions = [
  { value: undefined, label: 'Alle' },
  { value: 'true', label: '✅' },
  { value: 'false', label: '⛔️' },
]

const BooleanFilter = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ options: ({ value: undefined; label: strin... Remove this comment to see the full error message
  <SelectFilter options={booleanOptions} {...otherProps} />
)

export default BooleanFilter
