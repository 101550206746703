import Filter from "../Filter"

import UpdateButton from "./UpdateButton"

const columns = [
  {
    id: 'actions',
    Header: 'Update',
    Cell: UpdateButton,
    disableSortBy: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'E-mail',
    accessor: 'email',
    Filter: Filter.Search,
  },
  {
    Header: 'Name',
    accessor: 'fullName',
    Filter: Filter.Search,
  },
  {
    Header: 'Saxo Client ID',
    accessor: 'saxoClientId',
    Filter: Filter.Search,
  },
  {
    Header: 'State',
    accessor: 'saxoHouseTransferState',
    Filter: Filter.State,
  },
  {
    Header: 'Notification received at',
    accessor: 'saxoHouseTransferNotificationReceivedAt',
  },
  {
    Header: 'Documents signed at',
    accessor: 'saxoHouseTransferDocumentsSignedAt',
  },
  {
    Header: 'Pending at',
    accessor: 'saxoHouseTransferPendingAt',
  },
  {
    Header: 'Completed at',
    accessor: 'saxoHouseTransferCompletedAt',
  },
]

export default columns
