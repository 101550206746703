import { useQuery } from '@nord/ui/src/hooks'
import { useMemo } from 'react'

const formatCurrentPortfolioIds = (ids: string) => {
  const portfolioIds = ids.split(',')
  const formattedPortfolioIds = portfolioIds.map(Number)

  return formattedPortfolioIds
}

const useCurrentPortfolioIds = () => {
  const portfolioIdsSearchParam = useQuery('portfolioIds')

  const currentPortfolioIds = useMemo(
    () => (portfolioIdsSearchParam ? formatCurrentPortfolioIds(portfolioIdsSearchParam) : []),
    [portfolioIdsSearchParam],
  )

  return currentPortfolioIds
}

export default useCurrentPortfolioIds
