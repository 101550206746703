import IconButtonLink from '@nord/ui/src/components/IconButtonLink'
import LoadingButton from '@nord/ui/src/components/LoadingButton'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import React, { useCallback, useMemo, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'
import { useNavigate } from 'react-router-dom'

import Table from '../../../../components/Table'
import type { CustomGenerateAllocationDataPayloadType } from '../../../../types'
import { useAuthPagesContext } from '../../context/AuthPagesContext'
import { usePortfoliosTradeContext } from '../PortfoliosTradeContext'

import columns from './columns'

export interface OverviewModalProps {
  show: boolean
  onHide(): void
}

const OverviewModal = ({ show, onHide, ...modalProps }: OverviewModalProps) => {
  const { acceptedPortfolioStatus, portfoliosAllocationData } = usePortfoliosTradeContext()

  const { setTradedPortfolios } = useAuthPagesContext()

  const navigate = useNavigate()
  const [showAlert, setShowAlert] = useState(false)

  const getStatus = useCallback(
    (id: number) => {
      switch (acceptedPortfolioStatus[id]) {
        case true:
          return 'approved'
        case false:
          return 'rejected'
        default:
          return 'pending'
      }
    },
    [acceptedPortfolioStatus],
  )

  const acceptedPortfolioIds = useMemo(
    () => Object.keys(pickBy(acceptedPortfolioStatus, (value) => value === true)).map(Number),
    [acceptedPortfolioStatus],
  )

  const data = (portfoliosAllocationData ? [portfoliosAllocationData] : []).map(
    ({ user, portfolio, options }) => {
      const { email } = user
      const { id, saxoBankAccountId } = portfolio

      return {
        pageIndex: 0,
        id,
        status: getStatus(id),
        email,
        saxoBankAccountId,
        options,
      }
    },
  )

  const acceptedPortfolios = useMemo(
    () => data.filter(({ id }) => id && acceptedPortfolioIds.includes(id)),
    [acceptedPortfolioIds, data],
  )

  const callTradePortfolios = useApi('/limited_admin/etf_trading/trades', {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const portfoliosData = useMemo(
    (): CustomGenerateAllocationDataPayloadType => ({
      tradeType: 'custom',
      portfoliosData: acceptedPortfolios.map(({ id, options }) => ({ id, options })),
    }),
    [acceptedPortfolios],
  )

  const { loading, request: tradePortfolios } = useApiRequest(callTradePortfolios, {
    payload: {
      trade: portfoliosData,
    },
  })

  const handleTrade = async () => {
    const { success } = await tradePortfolios()

    if (!success) {
      setShowAlert(true)

      return
    }

    setTradedPortfolios(acceptedPortfolios)
    navigate('/portfolios/traded')
  }

  return (
    <Modal {...modalProps} className="text-dark" onHide={onHide} show={show} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">Overblik</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showAlert && (
          <Alert variant="danger" className="text-center">
            Something went wrong, please try again.
          </Alert>
        )}
        <Table data={data} columns={columns} />
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {isEmpty(acceptedPortfolioIds) && (
          <IconButtonLink
            to="/portfolios/investables"
            icon={ArrowLeft}
            iconPlacement="first"
            variant="link"
          >
            Gå tilbage til portefølje liste
          </IconButtonLink>
        )}
        <LoadingButton
          // @ts-expect-error TS(2322) FIXME: Type '{ children: (string | number)[]; onClick: ()... Remove this comment to see the full error message
          onClick={handleTrade}
          loading={loading}
          disabled={isEmpty(acceptedPortfolioIds)}
        >
          Handel {acceptedPortfolioIds.length} valgte porteføljer
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export default OverviewModal
