import React from 'react'

import Table from '../../../../../components/Table'

import columns from './columns'

export interface Props {
  data?: any[] // TODO: PropTypes.shape
}

const AllocationAccountsDataTable = ({ data }: Props) => <Table data={data} columns={columns} />

AllocationAccountsDataTable.defaultProps = {
  data: [],
}

export default AllocationAccountsDataTable
