import z from 'zod'

export const Portfolio = z.object({
  products: z.string(),
  cashBalance: z.number(),
  depotType: z.string(),
  esg: z.boolean(),
  id: z.number(),
  invested: z.boolean(),
  lastDeposit: z.number().nullable(),
  lastDepositDate: z.string().nullable(),
  orderHandlingAccepted: z.boolean(),
  pendingOrders: z.boolean(),
  riskScore: z.number(),
  saxoBankAccountId: z.string(),
  suitable: z.boolean(),
  totalValue: z.number(),
})

export type PortfolioType = z.infer<typeof Portfolio>
