import { useApi } from '@nord/ui/src/hooks'
import { useApiRequest } from '@nord/ui/src/hooks/useApi'
import constate from 'constate'
import { useState } from 'react'

import useSaxoHouseTransferPreferences from './useSaxoHouseTransferPreferences'

const useUsersPage = () => {
  const {
    filters: [tableFilters],
  } = useSaxoHouseTransferPreferences()

  const [apiFilter, setApiFilter] = useState(() =>
    tableFilters.reduce((acc, filter) => {
      acc[filter.id] = filter.value

      return acc
    }, {} as Record<string, string | string[]>),
  )

  const fetchUsers = useApi('/limited_admin/users/saxo_house_transfers', {
    withCredentials: true,
  })

  const {
    data,
    loading,
    request: getUsers,
  } = useApiRequest(fetchUsers, {
    enableDebounce: true,
    debounceTimeout: 500,
  })

  const { data: usersData, meta } = data || {}
  const { pagination } = meta || {}
  const { last: pageCount } = pagination || {}

  return {
    apiFilter,
    loading,
    usersData,
    pageCount,
    getUsers,
    setApiFilter,
  }
}

export const [UsersProvider, useUsersContext] = constate(useUsersPage)
