import IconButton from '@nord/ui/src/components/IconButton'
import MultiSelect from '@nord/ui/src/components/MultiSelect'
import Select from '@nord/ui/src/components/Select'
import Text from '@nord/ui/src/components/Text'
import { useDeepCompareMemoize } from '@nord/ui/src/hooks'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { ButtonGroup, DropdownButton } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Settings from 'react-feather/dist/icons/settings'
import {
  useFilters,
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useResizeColumns,
  useBlockLayout,
  // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
} from 'react-table'
import invariant from 'tiny-invariant'
import { useDebounce } from 'usehooks-ts'

import Page from '../../../../components/Page'
import Pagination from '../../../../components/Pagination'
import ScrollableTable from '../../../../components/ScrollableTable'
import Filter from '../Filter'
import type { Investable } from '../InvestablePortfoliosContext'
import { useInvestablePortfoliosContext } from '../InvestablePortfoliosContext'
import SortIcon from '../SortIcon'
import TradingButton from '../TradingButton'
import UpdateAlert from '../UpdateAlert'
import useInvestablesPreferences from '../useInvestablesPreferences'

import columns from './columns'
import styles from './index.module.scss'

const formatSorting = (isSorted: boolean, isSortedDesc: boolean) => {
  if (!isSorted) return undefined
  if (isSorted && !isSortedDesc) return 'asc'

  return 'desc'
}

const defaultColumn = {
  Filter: Filter.Search,
  minWidth: 30,
  width: 150,
}

const hiddenColumns = columns
  .filter((column) => column.isVisible === false)
  .map((column) => column.accessor)

const options = columns
  .filter((column) => column.isHideable !== false)
  .map((column) => ({
    value: column.accessor,
    label: column.Header,
  }))

export interface InvestablePortfoliosTableProps {
  data: Investable[]
}

const InvestablePortfoliosTable = ({ data }: InvestablePortfoliosTableProps) => {
  const {
    apiFilter,
    loading,
    pageCount,
    pageSize,
    refetchInvestablePortfolios,
    setPageSize,
    investablePortfoliosData,
  } = useInvestablePortfoliosContext()

  console.log({ investablePortfoliosData })

  const {
    filters: [apiFilters, setApiFilters],
    hidden: [tableHidden, setTableHidden],
    widths: [tableWidths, setTableWidths],
    sort: [apiSort, setApiSort],
    sortBy,
  } = useInvestablesPreferences()

  const initialState = useMemo(
    () => ({
      pageSize,
      sortBy,
      filters: apiFilters,
      hiddenColumns,
    }),
    [pageSize, sortBy, apiFilters],
  )

  const userColumns = useMemo(
    () =>
      columns.map((column) => {
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        const width = tableWidths[column.accessor]

        return width ? { ...column, width } : column
      }),
    [tableWidths],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, selectedRowIds, columnResizing, filters },
    setHiddenColumns,
  } = useTable(
    {
      columns: userColumns,
      data,
      defaultColumn,
      pageCount,
      autoResetSelectedRows: false,
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      autoResetSortBy: false,
      autoResetPage: false,
      getRowId: ({ portfolioId }: { portfolioId: number }) => portfolioId,
      initialState,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useBlockLayout,
  )

  useEffect(() => {
    setApiFilters(filters)
  }, [filters, setApiFilters])

  const columnWidths = useDeepCompareMemoize(columnResizing.columnWidths)
  const debouncedColumnWidths = useDebounce(columnWidths)

  useEffect(() => {
    if (debouncedColumnWidths) {
      setTableWidths((current) => ({
        ...current,
        ...debouncedColumnWidths,
      }))
    }
  }, [debouncedColumnWidths, setTableWidths])

  const selectedPortfolioIds = Object.keys(selectedRowIds).map(Number)

  const isAllSuitable = selectedPortfolioIds
    .map((id) => investablePortfoliosData?.find((p) => p.portfolioId === id))
    .every((p) => p?.suitable)

  const handleRowSelect = (row: {
    original: { pendingOrders?: unknown }
    toggleRowSelected(): void
  }) => {
    const {
      original: { pendingOrders },
    } = row

    if (pendingOrders) return
    if (loading) return

    row.toggleRowSelected()
  }

  useEffect(() => {
    const fetchDataForTable = async () => {
      await refetchInvestablePortfolios({
        page: {
          index: pageIndex + 1,
          limit: pageSize,
        },
        filter: apiFilter,
        sort: apiSort,
      })
    }

    fetchDataForTable()
  }, [apiFilter, apiSort, pageIndex, pageSize, refetchInvestablePortfolios])

  const handleSort = async (column: {
    canSort: boolean
    id: number
    isSorted: boolean
    isSortedDesc: boolean
    toggleSortBy(): Promise<void>
  }) => {
    const { canSort, id, toggleSortBy } = column

    if (!canSort) return

    await toggleSortBy()

    const { isSorted, isSortedDesc } = column

    const sortValue = formatSorting(isSorted, isSortedDesc)

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ [x: number]: string | undefine... Remove this comment to see the full error message
    setApiSort({ [id]: sortValue })
  }

  useEffect(() => {
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    setHiddenColumns(tableHidden.map(({ value }) => value).concat(hiddenColumns))
  }, [tableHidden, setHiddenColumns])

  const [isColumnSettingsOpen, setIsColumnSettingsOpen] = useState(false)
  const toggleColumnSettingsOpen = useCallback(() => setIsColumnSettingsOpen((c) => !c), [])

  const tradingButtons = (
    <ButtonGroup size="sm">
      <TradingButton
        tradeType="buy"
        portfolioIds={selectedPortfolioIds}
        disabled={!isAllSuitable}
      />

      <DropdownButton
        size="sm"
        as={ButtonGroup}
        title=""
        id="bg-nested-dropdown"
        data-cy="trade-dropdown"
      >
        <TradingButton tradeType="sell" portfolioIds={selectedPortfolioIds} dropdown />
        <TradingButton
          tradeType="rebalance"
          portfolioIds={selectedPortfolioIds}
          disabled={!isAllSuitable}
          dropdown
        />
        <TradingButton
          tradeType="risk_profile_change"
          portfolioIds={selectedPortfolioIds}
          disabled={!isAllSuitable}
          dropdown
        />
      </DropdownButton>
    </ButtonGroup>
  )

  return (
    <Page xs={12}>
      <Card>
        <div className="d-flex align-items-center m-5">
          <div className={styles.flex1} />
          <Text as="h2" variant="dark" className="mb-0">
            Brugere klar til investering
          </Text>
          <div className={classNames(styles.flex1, styles.gap, 'd-flex justify-content-end')}>
            <MultiSelect
              placeholder="Søg"
              // @ts-expect-error TS(2322) FIXME: Type '{ value: string | undefined; label: string |... Remove this comment to see the full error message
              options={options}
              values={tableHidden}
              isOpen={isColumnSettingsOpen}
              onChange={setTableHidden}
              onToggle={toggleColumnSettingsOpen}
            >
              <IconButton
                size="sm"
                variant="primary"
                onClick={toggleColumnSettingsOpen}
                icon={Settings}
                filled
              >
                Skjul kolonner
              </IconButton>
            </MultiSelect>
            {tradingButtons}
          </div>
        </div>
        <UpdateAlert />
        <ScrollableTable.Wrapper loading={loading}>
          <Table {...getTableProps()} striped bordered hover>
            <ScrollableTable.TableHead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    const { id } = column

                    return (
                      <th key={id} className="relative" {...column.getHeaderProps()}>
                        <span
                          role="none"
                          {...column.getSortByToggleProps()}
                          onClick={() => handleSort(column)}
                        >
                          {column.render('Header')}
                        </span>
                        <SortIcon column={column} />
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                        <div {...column.getResizerProps()} className={styles.resizer} />
                      </th>
                    )
                  })}
                </tr>
              ))}
            </ScrollableTable.TableHead>
            <ScrollableTable.TableBody {...getTableBodyProps()}>
              {isEmpty(page) ? (
                <ScrollableTable.NoResultsMessage loading={loading} />
              ) : (
                page.map((row: any) => {
                  prepareRow(row)

                  const {
                    original: { pendingOrders, portfolioId },
                  } = row

                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => handleRowSelect(row)}
                      className={classNames({
                        [styles.selectableTableRow]: !pendingOrders,
                      })}
                      data-cy={portfolioId}
                    >
                      {row.cells.map((cell: any) => (
                        <td
                          {...cell.getCellProps()}
                          className={styles.cell}
                          data-cy={cell.column.id}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })
              )}
            </ScrollableTable.TableBody>
          </Table>
        </ScrollableTable.Wrapper>
        <div className="mx-5">
          <Pagination.Wrapper>
            <div className={styles.flex1} />
            <Pagination
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              pageSize={pageSize}
              pageCount={pageCount}
              pageIndex={pageIndex}
              previousPage={previousPage}
              gotoPage={gotoPage}
              nextPage={nextPage}
            />
            <div className="ml-4">
              <Select
                // @ts-expect-error TS(2322) FIXME: Type '{ className: string; options: { value: strin... Remove this comment to see the full error message
                className={styles.rowsPerPage}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' },
                ]}
                value={String(pageSize)}
                onValueChange={(value) => setPageSize(parseInt(value, 10))}
                menuPlacement="top"
              />
            </div>
            <div className={classNames('d-flex justify-content-end', styles.flex1)}>
              {tradingButtons}
            </div>
          </Pagination.Wrapper>
        </div>
      </Card>
    </Page>
  )
}

export default InvestablePortfoliosTable
