import z from 'zod'

import { BatchTradeType } from './BatchTradeType'
import { CustomOptions } from './CustomOptions'
import { CustomTradeType } from './CustomTradeType'

export const CustomGenerateAllocationDataPayload = z.object({
  tradeType: CustomTradeType,
  portfoliosData: z.array(
    z.object({
      id: z.number(),
      options: CustomOptions,
    }),
  ),
})

export type CustomGenerateAllocationDataPayloadType = z.infer<
  typeof CustomGenerateAllocationDataPayload
>

export const BatchGenerateAllocationDataPayload = z.object({
  tradeType: BatchTradeType,
  portfoliosData: z.array(
    z.object({
      id: z.number(),
    }),
  ),
})

export type BatchGenerateAllocationDataPayloadType = z.infer<
  typeof BatchGenerateAllocationDataPayload
>

export const GenerateAllocationDataPayload = z.union([
  CustomGenerateAllocationDataPayload,
  BatchGenerateAllocationDataPayload,
])

export type GenerateAllocationDataPayloadType = z.infer<typeof GenerateAllocationDataPayload>
