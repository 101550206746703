const generalOrderKeys = [
  'id',
  'etf',
  'trader',
  'orderId',
  'tradeGroupId',
  'uic',
  'externalReference',
  'action',
  'quantity',
  'status',
  'sentAt',
  'succeededAt',
  'failedAt',
  'error',
]

const allocationKeys = [
  'allocationId',
  'allocationKey',
  'ownerAccountKey',
  'allocationKeyName',
  'allocationUnitType',
  'marginHandling',
  'oneTime',
]

/**
 * Returns a sorted object by keys passed
 *
 * @param {object} data - The object which should be formatted
 * @param {array} keys - Array of keys in which order
 * @return object with ordered keys
 * @example
 *
 *
 * const data = { age: '25',  email: 'adam@mail.dk', name: 'Adam' }
 * const keys = ['name', 'email', 'age']
 *
 * const orderedData = orderByKeys(data, keys)
 *
 * // orderedData
 * { name: 'Adam', email: 'adam@mail.dk', age: '25' }
 */
export const orderByKeys = (data: any, keys: any) => JSON.parse(JSON.stringify(data, keys))

const formatData = (data: any, keys: any) => {
  const orderedData = orderByKeys(data, keys)
  const formattedData = Object.entries(orderedData).map(([key, value]) => ({
    name: key,
    value,
  }))

  return formattedData
}

export const formatGeneralOrderData = (data: any) => formatData(data, generalOrderKeys)

export const formatAllocationData = (data: any) => formatData(data, allocationKeys)
