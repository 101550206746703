import Paginator, { usePaginationContext } from '@nord/ui/src/components/Paginator'
import Text from '@nord/ui/src/components/Text'
import type { ReactNode } from 'react'
import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import useCurrentPortfolioIds from '../../hooks/useCurrentPortfolioIds'

export interface PageContainerProps {
  children: React.ReactNode
}

const PageContainer = ({ children }: PageContainerProps) => {
  const { number } = usePaginationContext()
  const currentPortfolioIds = useCurrentPortfolioIds()

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Text as="div" align="center" size="xs" variant="muted" className="mb-4">
              <span>{number}</span> /{' '}
              <div className="d-inline-flex">{currentPortfolioIds.length}</div>
            </Text>
            {children}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export interface AllocationPageProps {
  children: React.ReactNode
  pageIndex?: number
  render?(pagination: unknown): ReactNode
}

const AllocationPage = ({ render, children, ...otherProps }: AllocationPageProps) => (
  <Paginator.Page
    render={(pagination: any) => (
      <>
        {render && render(pagination)}
        {children}
      </>
    )}
    {...otherProps}
    containerComponent={PageContainer}
  />
)

AllocationPage.defaultProps = {
  render: undefined,
  pageIndex: undefined,
}

export default AllocationPage
