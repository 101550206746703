import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration/config'
import isBoolean from 'lodash/isBoolean'
import React from 'react'

import { humanizedBoolean } from '../../utils'

const backendDomain = getConfig('domains.backend')

const columns = [
  {
    Header: 'Detaljer',
    accessor: 'name',
  },
  {
    Header: 'Kunde',
    accessor: 'value',
    Cell: ({ data, row, cell: { value } }: any) => {
      if (row.original.name === 'Produkter') {
        const portfolioId = data.find((item: any) => item.name === 'Portefølje ID').value

        return (
          <div className="d-flex justify-content-between">
            <span>{value}</span>
            <Text
              as="a"
              href={`${backendDomain}/limited_admin/portfolios/${portfolioId}/edit`}
              target="_blank"
            >
              Rediger
            </Text>
          </div>
        )
      }

      if (isBoolean(value)) {
        return humanizedBoolean(value)
      }

      return value
    },
  },
]

export default columns
