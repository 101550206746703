import AfterTrade from './AfterTrade'
import AfterTradeDifference from './AfterTradeDifference'
import WithDeviationRange from './WithDeviationRange'

const Allocation = {
  AfterTrade,
  AfterTradeDifference,
  WithDeviationRange,
}

export default Allocation
