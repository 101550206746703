import Select from '@nord/ui/src/components/Select'
import pick from 'lodash/pick'
import React from 'react'

import { useInvestablePortfoliosContext } from '../InvestablePortfoliosContext'

export interface Props {
  column: {
    id?: string
    filterValue?: string | boolean
    setFilter?: (...args: any[]) => any
  }
}

const SelectFilter = ({ column: { filterValue, setFilter, id }, ...otherProps }: Props) => {
  const { apiFilter, setApiFilter } = useInvestablePortfoliosContext()
  const selectProps = pick(otherProps, ['options'])

  const handleValueChange = (value: any) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setFilter(value)
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    setApiFilter({ ...apiFilter, [id]: value })
  }

  return (
    <div data-cy={`${id}-select-filter`}>
      <Select
        // @ts-expect-error TS(2322) FIXME: Type 'string | boolean | undefined' is not assigna... Remove this comment to see the full error message
        value={filterValue}
        onValueChange={handleValueChange}
        className="mt-3"
        menuPortalTarget={document.body}
        {...selectProps}
      />
    </div>
  )
}

export default SelectFilter
