import classNames from 'classnames'
import React from 'react'

import styles from './Wrapper.module.scss'

export interface Props {
  children: React.ReactNode
}

const Wrapper = ({ children }: Props) => (
  <div
    className={classNames(
      'd-flex',
      'align-items-center',
      'justify-content-center',
      'bg-white',
      'w-100',
      'h-100',
      styles.paginationWrapper,
    )}
  >
    {children}
  </div>
)

export default Wrapper
