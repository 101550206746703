import IconButton from '@nord/ui/src/components/IconButton'
import useApi from '@nord/ui/src/hooks/useApi'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import RefreshCw from 'react-feather/dist/icons/refresh-cw'

import { useInvestablePortfoliosContext } from '../InvestablePortfoliosContext'

export interface Props {
  cell: any // TODO: PropTypes.shape()
  row: any // TODO: PropTypes.shape()
}

const UpdateButton = ({
  cell: { value: portfolioId },
  row: {
    original: { email },
  },
}: Props) => {
  const { onShowAlert, onAlertDataChange, refetchInvestablePortfolios } =
    useInvestablePortfoliosContext()

  const updatePortfolio = useApi(`/limited_admin/portfolios/investables/${portfolioId}/refresh`, {
    method: 'POST',
    withCredentials: true,
    errorHandling: { ignore: { client: true } },
  })

  const handleUpdate = async (event: any) => {
    event.stopPropagation()

    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    const { data, success } = await updatePortfolio()

    const { errors } = data || {}
    const { invalid } = errors || {}

    const errorMessage = !isEmpty(invalid) && invalid[0]

    const variant = success ? 'success' : 'danger'
    const message = success ? `Opdaterede ${email}` : errorMessage

    onAlertDataChange({
      variant,
      message,
    })
    onShowAlert()

    if (success) await refetchInvestablePortfolios()
  }

  return (
    <IconButton
      onClick={handleUpdate}
      icon={RefreshCw}
      variant="link"
      className="btn-anchor"
      title="Opdater"
    />
  )
}

export default UpdateButton
