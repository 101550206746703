const columns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Konto ID',
    accessor: 'portfolioSaxoBankAccountId',
  },
  {
    Header: 'Portefølje ID',
    accessor: 'portfolioId',
  },
  {
    Header: 'Handling',
    accessor: 'action',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Status',
    accessor: 'nordStatus',
  },
]

export default columns
