import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'

import { orderByKeys } from '../../pages/AuthPages/OrdersPage/OrderModal/formatData'
import type { UserType } from '../../types'
import { humanizedDate } from '../../utils'

const keysTranslations = {
  email: 'Email',
  saxoBankAccountId: 'Konto ID',
  portfolioId: 'Portefølje ID',
  depotType: 'Depottype',
  riskScore: 'Risikoprofil',
  orderHandlingAccepted: 'Handel godkendt',
  esg: 'ESG',
  suitable: 'Egnet',
  totalValue: 'Porteføljeværdi',
  cashBalance: 'Kontanter',
  lastDeposit: 'Sidste indbetaling',
  userFreeAssetsPortfoliosTotalCashBalance: 'Kontanter på tværs af konti',
  userHasFreeAssetsPortfolioInOverdraft: 'Konto/konti i overtræk',
  products: 'Produkter',
  invested: 'Investeret',
} as Record<string, string>

const currencyKeys = [
  'totalValue',
  'lastDeposit',
  'cashBalance',
  'userFreeAssetsPortfoliosTotalCashBalance',
]

const formattedKeys = Object.keys(keysTranslations)

function formatValue(key: string, value: unknown) {
  if ((key === 'lastDepositDate' && typeof value === 'string') || value instanceof Date)
    return humanizedDate(value)

  if (currencyKeys.includes(key)) return numberToCurrency(value)

  return value
}

const formatData = (data: UserType) => {
  const orderedData = orderByKeys(data, formattedKeys)
  const formattedData = Object.entries(orderedData).map(([key, value]) => ({
    name: keysTranslations[key],
    value: formatValue(key, value),
  }))

  return formattedData
}

export default formatData
