import { formatRequestData, formatResponseData } from '@nord/ui/src/hooks/useApi/formatData'
import type { ChannelNameWithParams, Mixin, Subscription } from '@rails/actioncable'
import { useEffect, useState, useRef, useCallback } from 'react'

import consumer from '../channels/consumer'

type DataChannel = Subscription<typeof consumer> & Mixin

type ChannelName = ChannelNameWithParams

interface SendParams {
  action: string
  payload?: Record<string, unknown>
}

interface Callbacks {
  connected?(): void
  disconnected?(): void
  initialized?(): void
  received?(data: unknown): void
}

const useDataChannel = ({ channel, topic }: ChannelName) => {
  const [queue, setQueue] = useState<SendParams[]>([])
  const [connected, setConnected] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const subscriptionRef = useRef<DataChannel>()

  const unsubscribe = useCallback(() => {
    setSubscribed(false)

    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe()
      subscriptionRef.current = undefined
    }
  }, [])

  const subscribe = useCallback(
    (callbacks: Callbacks) => {
      const channelName = { channel, topic }
      const subscription = consumer.subscriptions.create(channelName, {
        received(data) {
          callbacks.received?.(formatResponseData(data))
        },
        initialized() {
          setSubscribed(true)
          callbacks.initialized?.()
        },
        connected() {
          setConnected(true)
          callbacks.connected?.()
        },
        disconnected() {
          setConnected(false)
          callbacks.disconnected?.()
        },
      })

      subscriptionRef.current = subscription
    },
    [channel, topic],
  )

  const send = useCallback((params: SendParams) => {
    setQueue((prevState) => [...prevState, params])
  }, [])

  useEffect(() => {
    if (queue.length === 0) return
    if (!connected || !subscribed) return
    if (!subscriptionRef.current) return

    const [{ action, payload }, ...rest] = queue
    subscriptionRef.current.perform(action, payload && formatRequestData(payload))
    setQueue(rest)
  }, [connected, subscribed, queue])

  useEffect(() => unsubscribe, [unsubscribe])

  return {
    connected,
    subscribed,
    subscribe,
    unsubscribe,
    send,
  }
}

export default useDataChannel
