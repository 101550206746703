import React from 'react'

import Page from '../../../components/Page'

import LoginForm from './LoginForm'

const LoginPage = () => (
  <Page
    xs={12}
    md={{ offset: 1, span: 10 }}
    lg={{ offset: 2, span: 8 }}
    xl={{ offset: 3, span: 6 }}
    withCard
  >
    <LoginForm />
  </Page>
)

export default LoginPage
