import React from 'react'

import { useInvestablePortfoliosContext } from './InvestablePortfoliosContext'
import InvestablePortfoliosTable from './InvestablePortfoliosTable'

const InvestablePortfoliosPage = () => {
  const { investablePortfoliosData } = useInvestablePortfoliosContext()

  return <InvestablePortfoliosTable data={investablePortfoliosData ?? []} />
}

export default InvestablePortfoliosPage
