import z from 'zod'

import { Allocation } from './Allocation'
import { BatchTradeType } from './BatchTradeType'
import { CustomOptions } from './CustomOptions'
import { CustomTradeType } from './CustomTradeType'
import { Portfolio } from './Portfolio'
import { User } from './User'

const BasePortfolioAllocationsChannelData = z.object({
  user: User,
  portfolio: Portfolio,
  allocations: z.array(Allocation),
})

export const CustomPortfolioAllocationsChannelData = BasePortfolioAllocationsChannelData.extend({
  tradeType: CustomTradeType,
  options: CustomOptions,
})

export type CustomPortfolioAllocationsChannelDataType = z.infer<
  typeof CustomPortfolioAllocationsChannelData
>

export const BatchPortfolioAllocationsChannelData = BasePortfolioAllocationsChannelData.extend({
  tradeType: BatchTradeType,
  options: z.object({}),
})

export type BatchPortfolioAllocationsChannelDataType = z.infer<
  typeof BatchPortfolioAllocationsChannelData
>

export const PortfolioAllocationsChannelData = z.union([
  CustomPortfolioAllocationsChannelData,
  BatchPortfolioAllocationsChannelData,
])

export type PortfolioAllocationsChannelDataType = z.infer<typeof PortfolioAllocationsChannelData>
