import React from 'react'

import styles from './TableHead.module.scss'

export interface Props {
  children: React.ReactNode
}

const TableHead = ({ children }: Props) => (
  <thead className={styles.stickyTableHead}>{children}</thead>
)

export default TableHead
