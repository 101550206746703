import Select from '@nord/ui/src/components/Select'
import React from 'react'

import { useInvestablePortfoliosContext } from '../InvestablePortfoliosContext'

interface Option {
  label: string
  value: string
}

export interface Props {
  column: {
    id?: string
    filterValue?: string[]
    setFilter?: (...args: any[]) => any
  }
  options: Option[]
}

const SelectFilter = ({ column: { filterValue, setFilter, id }, options }: Props) => {
  const { apiFilter, setApiFilter } = useInvestablePortfoliosContext()
  const selectedOptions = options.filter((option) => filterValue?.includes(option.value))

  const handleSelect = (newOptions: Option[]) => {
    const newValue = newOptions.map((option) => option.value)

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setFilter(newValue)
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    setApiFilter({ ...apiFilter, [id]: newValue })
  }

  return (
    <div data-cy={`${id}-select-filter`}>
      <Select
        // @ts-expect-error TS(2322) FIXME: Type 'string | boolean | undefined' is not assigna... Remove this comment to see the full error message
        selectedOption={selectedOptions}
        onSelect={handleSelect}
        className="mt-3"
        menuPortalTarget={document.body}
        options={options}
        isMulti
      />
    </div>
  )
}

export default SelectFilter
