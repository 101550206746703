import { numberToPercent, numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'

import useTradePreferences from '../../../../hooks/useTradePreferences'

const useFormatAllocationValue = (value: number) => {
  const {
    displayType: [displayType],
  } = useTradePreferences()

  switch (displayType) {
    case 'percentage':
      return numberToPercent(value / 100, {
        maximumFractionDigits: 2,
      })
    case 'value':
      return numberToCurrency(value)
    default:
      return value
  }
}

export default useFormatAllocationValue
