import AutocompleteSelect from '@nord/ui/src/components/AutocompleteSelect'
import Text from '@nord/ui/src/components/Text'
import { useApi, useDebouncedCallback } from '@nord/ui/src/hooks'
import React from 'react'
import { Card } from 'react-bootstrap'
import { Outlet, useNavigate } from 'react-router-dom'

import Page from '../../../components/Page'

const UsersIndexPage = () => {
  const navigate = useNavigate()

  const fetchUsers = useApi('/limited_admin/users', {
    withCredentials: true,
  })

  const handleLoadOptions = useDebouncedCallback(
    async (query: string) => {
      const { data } = await fetchUsers({ query })

      return data.data.map((user: any) => ({
        label: `${user.firstName} ${user.lastName} (${user.email})`,
        value: user.id,
      }))
    },
    500,
    {},
  )

  const handleSelect = ({ value }: { value: number }) => {
    navigate(`/users/${value}`)
  }

  return (
    <Page
      xs={12}
      md={{ offset: 1, span: 10 }}
      lg={{ offset: 2, span: 8 }}
      xl={{ offset: 3, span: 6 }}
    >
      <Card className="p-4">
        <Text as="h2" variant="dark">
          Brugere
        </Text>
        <AutocompleteSelect loadOptions={handleLoadOptions} onSelect={handleSelect} />
        <Outlet />
      </Card>
    </Page>
  )
}

export default UsersIndexPage
