import { useLocalStorage } from 'usehooks-ts'

const useOrdersPreferences = () => {
  const filters = useLocalStorage<{ id: string; value: string }[]>('ordersFilters', [])

  const sort = useLocalStorage('ordersSort', { lastStatusUpdatedAt: 'desc' })
  const sortBy = Object.entries(sort[0]).map(([id, order]) => ({ [order]: true, id }))

  return { filters, sort, sortBy }
}

export default useOrdersPreferences
