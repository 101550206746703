import IconButton from '@nord/ui/src/components/IconButton'
import Paginator, { usePaginationContext } from '@nord/ui/src/components/Paginator'
import { useAtomValue } from 'jotai'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Check from 'react-feather/dist/icons/check'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import X from 'react-feather/dist/icons/x'
import { Link } from 'react-router-dom'

import useCurrentPortfolioIds from '../../../../hooks/useCurrentPortfolioIds'
import { tradeAtom } from '../atoms'
import OverviewModal from '../OverviewModal'
import { usePortfoliosTradeContext } from '../PortfoliosTradeContext'

const NextButton = () => {
  const { index, onNext } = usePaginationContext()

  const {
    acceptedPortfolioStatus,
    isGenerating,
    portfoliosAllocationData,
    showOverviewModal,
    onHideOverviewModal,
    setAcceptedPortfolioStatus,
  } = usePortfoliosTradeContext()

  const currentPortfolioIds = useCurrentPortfolioIds()

  const currentPortfolioId = currentPortfolioIds[index]

  const { user, portfolio, allocations } = portfoliosAllocationData || {}
  const trade = useAtomValue(tradeAtom)

  const isLoading = isEmpty(user) || isEmpty(portfolio) || isEmpty(allocations)
  const isDisabled =
    isLoading ||
    isGenerating ||
    (trade?.cashAmount === 0 &&
      Object.values(trade?.allocation ?? []).every((allocation) => allocation === 0))

  const handleAccept = () => {
    setAcceptedPortfolioStatus({
      ...acceptedPortfolioStatus,
      [currentPortfolioId]: true,
    })
    onNext()
  }

  return (
    <>
      <div className="d-flex">
        <Link to="/portfolios/investable">
          <IconButton icon={X} variant="danger" disabled={isLoading}>
            Afvis
          </IconButton>
        </Link>
        <IconButton
          className="ml-3"
          icon={Check}
          variant="success"
          onClick={handleAccept}
          disabled={isDisabled}
          filled
        >
          Godkend
        </IconButton>
      </div>
      <OverviewModal show={showOverviewModal} onHide={onHideOverviewModal} />
    </>
  )
}

const Navigation = ({ ...props }) => (
  <Paginator.Navigation
    nextButton={<NextButton />}
    className="align-items-center"
    showOnLast
    {...props}
  />
)

export default Navigation
