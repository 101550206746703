import { usePortfoliosTradesContext } from '../PortfoliosTradesContext'

import useFormatAllocationValue from './useFormatAllocationValue'

const useAllocationValue = ((displayValues: Record<string, number>, formatValue = true) => {
  const { displayType } = usePortfoliosTradesContext()

  const value = displayValues[displayType]
  const formattedValue = useFormatAllocationValue(value)

  if (!formatValue) return value

  return formattedValue
}) as {
  (displayValues: Record<string, number>, formatValue: false): number
  (displayValues: Record<string, number>, formatValue?: true): string
}

export default useAllocationValue
