import constate from 'constate'
import { useAtomValue } from 'jotai'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useMemo, useState } from 'react'
import { useEffectOnce } from 'usehooks-ts'

import useCurrentPortfolioIds from '../../../hooks/useCurrentPortfolioIds'
import useTradePreferences from '../../../hooks/useTradePreferences'

import { portfoliosAllocationDataAtom, tradeAtom } from './atoms'
import usePortfolioAllocationsDataChannel from './hooks/usePortfolioAllocationsDataChannel'

const usePortfoliosTrade = () => {
  const currentPortfolioIds = useCurrentPortfolioIds()
  const {
    optionType: [optionType],
  } = useTradePreferences()
  const [acceptedPortfolioStatus, setAcceptedPortfolioStatus] = useState<Record<number, boolean>>(
    {},
  )
  const [showOverviewModal, setShowOverviewModal] = useState(false)
  const handleShowOverviewModal = () => setShowOverviewModal(true)
  const handleHideOverviewModal = () => setShowOverviewModal(false)
  const trade = useAtomValue(tradeAtom)

  const portfoliosAllocationData = useAtomValue(portfoliosAllocationDataAtom)
  const { isGenerating, generateAllocationData } = usePortfolioAllocationsDataChannel()

  const tradeType = useMemo(() => {
    if (!trade) return undefined

    // This is equal to changing ETFs
    if (trade.cashAmount === 0) {
      return 'custom'
    }

    if (optionType === 'normal' && trade.cashAmount < 0) {
      return 'buy'
    }

    if (optionType === 'normal' && trade.cashAmount > 0) {
      return 'sell'
    }

    if (optionType === 'rebalance') {
      return 'rebalance'
    }

    if (optionType === 'risk_profile_change') {
      return 'risk_profile_change'
    }

    return undefined
  }, [optionType, trade])

  const handleSubmitTrade = useCallback(() => {
    if (tradeType && trade) {
      generateAllocationData(tradeType, trade)
    }
  }, [tradeType, trade, generateAllocationData])

  useEffectOnce(handleSubmitTrade)

  const isLoadingPortfolioAllocations =
    !isEmpty(portfoliosAllocationData) &&
    Object.keys(portfoliosAllocationData).length !== currentPortfolioIds.length

  return {
    acceptedPortfolioStatus,
    isGenerating,
    isLoadingPortfolioAllocations,
    portfoliosAllocationData,
    showOverviewModal,
    onHideOverviewModal: handleHideOverviewModal,
    onShowOverviewModal: handleShowOverviewModal,
    setAcceptedPortfolioStatus,
    submitTrade: handleSubmitTrade,
    generateAllocationData,
  }
}

export const [PortfoliosTradeProvider, usePortfoliosTradeContext] = constate(usePortfoliosTrade)
