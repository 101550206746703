import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Icon from '@nord/ui/src/components/Icon'
import formatDate from '@nord/ui/src/utilities/formatDate'
import uniq from 'lodash/uniq'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronDown from 'react-feather/dist/icons/chevron-down'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronRight from 'react-feather/dist/icons/chevron-right'

import Filter from '../Filter'
import { useOrdersPageContext } from '../OrdersPageContext'

export interface DetailsButtonProps {
  cell: { value?: { orderId: number; tradeGroupId: number } }
}

const DetailsButton = ({ cell: { value } }: DetailsButtonProps) => {
  const { onShowOrderModal } = useOrdersPageContext()

  if (!value) return '-'

  const handleClick = () => onShowOrderModal(value)

  return (
    <BaffleButton variant="link" onClick={handleClick} className="p-0">
      Se mere
    </BaffleButton>
  )
}

export interface ExpandedIconProps {
  isExpanded?: boolean
}

const ExpandedIcon = ({ isExpanded }: ExpandedIconProps) => {
  const icon = isExpanded ? ChevronDown : ChevronRight

  return <Icon icon={icon} size="18" />
}

ExpandedIcon.defaultProps = {
  isExpanded: false,
}

export interface ExpandedHeaderProps {
  getToggleAllRowsExpandedProps(): Record<string, unknown>
  isAllRowsExpanded: boolean
}

const ExpandedHeader = ({
  getToggleAllRowsExpandedProps,
  isAllRowsExpanded,
}: ExpandedHeaderProps) => (
  <span {...getToggleAllRowsExpandedProps()} className="d-block w-100">
    <ExpandedIcon isExpanded={isAllRowsExpanded} />
  </span>
)

export interface ExpandedCellProps {
  row: {
    canExpand?: boolean
    isExpanded: boolean
    getToggleRowExpandedProps(arg0: Record<string, unknown>): Record<string, unknown>
  }
}

const ExpandedCell = ({
  row: { canExpand, isExpanded, getToggleRowExpandedProps },
}: ExpandedCellProps) => {
  if (!canExpand) return null

  return (
    <span {...getToggleRowExpandedProps({})} className="d-block w-100">
      <ExpandedIcon isExpanded={isExpanded} />
    </span>
  )
}

const StatusDetailsCell = ({ cell: { value } }: { cell: { value: unknown } }) => {
  if (!value) return '-'

  return Object.entries(value)
    .map(([key, amount]) => `${key}: ${amount}`)
    .join(', ')
}

const ArrayCell = ({ cell: { value } }: { cell: { value: string | string[] } }) => {
  const content = Array.isArray(value) ? uniq(value).join(', ') : value ?? '-'

  return (
    <span
      title={content}
      style={{
        display: 'block',
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {content}
    </span>
  )
}

const columns = [
  {
    id: 'expander',
    Header: ExpandedHeader,
    Cell: ExpandedCell,
  },
  {
    Header: 'Sidst opdateret',
    accessor: 'lastStatusUpdatedAt',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) => formatDate(value, 'PPPp'),
  },
  {
    Header: 'Trader',
    accessor: 'trader',
    disableSortBy: true,
    Filter: Filter.Search,
  },
  {
    Header: 'Action/Type',
    id: 'tradeType',
    accessor: (row: any) => row.tradeType || row.action,
    disableSortBy: true,
    Filter: Filter.TradeType,
  },
  {
    Header: 'Saxo status',
    accessor: 'saxoStatus',
    disableSortBy: true,
    Filter: Filter.Status,
  },
  {
    Header: 'Saxo status detaljer',
    accessor: 'saxoStatusDetails',
    id: 'saxoStatusDetails',
    Cell: StatusDetailsCell,
    disableSortBy: true,
  },
  {
    Header: 'NORD status',
    accessor: 'nordStatus',
    disableSortBy: true,
    Filter: Filter.Status,
  },
  {
    Header: 'NORD status detaljer',
    accessor: 'nordStatusDetails',
    id: 'nordStatusDetails',
    Cell: StatusDetailsCell,
    disableSortBy: true,
  },
  {
    Header: 'ETF',
    accessor: 'etf',
    Cell: ({ cell: { value } }: { cell: { value: unknown } }) => value || '-',
    disableSortBy: true,
  },
  {
    Header: 'Kvantitet',
    id: 'quantity',
    accessor: (row: any) => row.ordersQuantity || row.quantity,
    disableSortBy: true,
  },
  {
    Header: 'Detaljer',
    id: 'tradeGroupId',
    accessor: (row: any) => row.tradeGroupId && { tradeGroupId: row.tradeGroupId, orderId: row.id },
    Cell: DetailsButton,
    disableSortBy: true,
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: true,
    Cell: ArrayCell,
    Filter: Filter.Search,
  },
  {
    Header: 'Saxo konto id',
    accessor: 'saxoBankAccountId',
    disableSortBy: true,
    Cell: ArrayCell,
    Filter: Filter.Search,
  },
]

export default columns
