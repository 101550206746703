import Icon from '@nord/ui/src/components/Icon'
import Loader from '@nord/ui/src/components/Loader'
import { usePaginationContext } from '@nord/ui/src/components/Paginator'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import CheckSquare from 'react-feather/dist/icons/check-square'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Square from 'react-feather/dist/icons/square'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import XSquare from 'react-feather/dist/icons/x-square'

import { usePortfoliosTradeContext } from '../PortfoliosTradeContext'

export interface LoadingCellProps {
  cell: { value?: unknown }
}

const LoadingCell = ({ cell: { value } }: LoadingCellProps) => value || <Loader />

const statusIcons = {
  approved: { icon: CheckSquare, variant: 'text-dark-green' },
  rejected: { icon: XSquare, variant: 'text-danger' },
  pending: { icon: Square, variant: 'text-dark' },
}

export interface StatusTextProps {
  cell: { value: keyof typeof statusIcons }
}

const StatusText = ({ cell: { value } }: StatusTextProps) => {
  const { icon, variant } = statusIcons[value]

  return <Icon size="20" icon={icon} className={variant} />
}

export interface PageChangeButtonProps {
  cell: { value: number }
}

const PageChangeButton = ({ cell: { value: pageNumber } }: PageChangeButtonProps) => {
  const { onChange, number: currentPageNumber } = usePaginationContext()
  const { onHideOverviewModal } = usePortfoliosTradeContext()

  const handlePageChange = () => {
    if (pageNumber !== currentPageNumber) onChange(pageNumber)
    onHideOverviewModal()
  }

  return (
    <Button variant="link" className="btn-filled p-0" onClick={handlePageChange}>
      Gå til
    </Button>
  )
}

const columns = [
  {
    Header: 'Email',
    accessor: 'email',
    Cell: LoadingCell,
  },
  {
    Header: 'Konto ID',
    accessor: 'saxoBankAccountId',
    Cell: LoadingCell,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: StatusText,
  },
  {
    Header: 'Kundes portefølje',
    accessor: 'pageIndex',
    Cell: PageChangeButton,
  },
]

export default columns
