import formatDate from '@nord/ui/src/utilities/formatDate'
import startCase from 'lodash/startCase'

const formatValue = (data: any) => {
  const {
    row: {
      original: { name, value },
    },
  } = data

  if (name.endsWith('At')) return value ? formatDate(value, 'PPPp') : '-'

  return value || '-'
}

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ cell: { value } }: any) => startCase(value),
  },
  {
    Header: 'Value',
    accessor: 'value',
    Cell: (data: any) => formatValue(data),
  },
]

export default columns
