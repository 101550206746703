import Text from '@nord/ui/src/components/Text'
import { numbersToPercentRange, numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

import useTradePreferences from '../../../../../hooks/useTradePreferences'
import useAllocationValue from '../useAllocationValue'

const useAllocationRange = (
  minDisplayValues: Record<string, number>,
  maxDisplayValues: Record<string, number>,
) => {
  const {
    displayType: [displayType],
  } = useTradePreferences()

  const minValue = minDisplayValues[displayType]
  const maxValue = maxDisplayValues[displayType]

  switch (displayType) {
    case 'percentage':
      return numbersToPercentRange(minValue / 100, maxValue / 100)
    case 'value':
      return `${numberToCurrency(minValue)} - ${numberToCurrency(maxValue)}`
    default:
      return `${minValue} - ${maxValue}`
  }
}

export interface WithDeviationRangeProps {
  cell: { value: Record<string, number> }
  row: {
    original: {
      targetAllocationMin: Record<string, number>
      targetAllocationMax: Record<string, number>
      category: string
    }
    values: { name: string }
  }
}

const WithDeviationRange = ({
  cell: { value },
  row: {
    original: { targetAllocationMin, targetAllocationMax, category },
    values: { name },
  },
}: WithDeviationRangeProps) => {
  const allocationWithDeviationValue = useAllocationValue(value)

  const allocationWithDeviationRange = useAllocationRange(targetAllocationMin, targetAllocationMax)

  if (name === 'CASH') return allocationWithDeviationValue

  return (
    <>
      {category === 'cash' && Number.isInteger(allocationWithDeviationValue)
        ? numberToCurrency(allocationWithDeviationValue)
        : allocationWithDeviationValue}{' '}
      <Text variant="primary">({allocationWithDeviationRange})</Text>
    </>
  )
}

export default WithDeviationRange
