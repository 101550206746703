import NordHeader from '@nord/ui/src/components/Header'
import useApi from '@nord/ui/src/hooks/useApi'
import classNames from 'classnames'
import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link, useNavigate } from 'react-router-dom'

import { useAppContext } from '../../context/AppContext'
import useCurrentUser from '../../hooks/useCurrentUser'
import useIsSignedIn from '../../hooks/useIsSignedIn'

const Navigation = () => {
  const isSignedIn = useIsSignedIn()
  const navigate = useNavigate()
  const { setCurrentUser } = useAppContext()
  const currentUser = useCurrentUser()

  const signOut = useApi('/admin_users/sign_out', {
    method: 'DELETE',
    withCredentials: true,
  })

  const handleSignOut = async () => {
    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    const { success } = await signOut()

    if (!success) return

    setCurrentUser(undefined)
    if (currentUser) navigate(`/brugere/logind?email=${currentUser.email}`)
  }

  return (
    <NordHeader mobile>
      <Navbar role="banner">
        <NordHeader.Logo variant="light" />
        <Nav className="w-100 justify-content-between">
          <div className="d-flex">
            <Nav.Item className={classNames({ invisible: !isSignedIn })}>
              <NordHeader.Link as={Link} to="/portfolios/investable">
                Porteføljer der skal investeres
              </NordHeader.Link>
            </Nav.Item>
            <Nav.Item className={classNames({ invisible: !isSignedIn }, 'ml-3')}>
              <NordHeader.Link as={Link} to="/orders">
                Ordrer
              </NordHeader.Link>
            </Nav.Item>
            <Nav.Item className={classNames({ invisible: !isSignedIn }, 'ml-3')}>
              <NordHeader.Link as={Link} to="/users">
                Brugere
              </NordHeader.Link>
            </Nav.Item>
            <Nav.Item className={classNames({ invisible: !isSignedIn }, 'ml-3')}>
              <NordHeader.Link as={Link} to="/saxo-house-transfers">
                Saxo Flytninger
              </NordHeader.Link>
            </Nav.Item>
          </div>
          <Nav.Item className={classNames({ invisible: !isSignedIn })}>
            <NordHeader.Link onClick={handleSignOut}>Log ud</NordHeader.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    </NordHeader>
  )
}

export default Navigation
