import Text from '@nord/ui/src/components/Text'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import { useOrdersPageContext } from '../OrdersPageContext'

import AllocationAccountsDataTable from './AllocationAccountsDataTable'
import AllocationDataTable from './AllocationDataTable'
import { formatGeneralOrderData, formatAllocationData } from './formatData'
import GeneralOrderDataTable from './GeneralOrderDataTable'
import PortfolioEtfOrdersTable from './PortfolioEtfOrdersTable'

const OrderModal = ({ ...otherProps }) => {
  const { showOrderModal, onHideOrderModal, currentOrder: orderData } = useOrdersPageContext()

  const { portfolioEtfOrders, allocation, ...rawOrderData } = orderData || {}
  const { data: allocationDetailedData, key: allocationKey, id: allocationId } = allocation || {}
  const { participatingAccountsInfo, ...rawAllocationData } = allocationDetailedData || {}

  const generalOrderData = formatGeneralOrderData(rawOrderData)
  const allocationData = formatAllocationData({
    allocationKey,
    allocationId,
    ...rawAllocationData,
  })

  return (
    <Modal
      show={showOrderModal}
      onHide={onHideOrderModal}
      size="xl"
      fullscreen="true"
      centered
      {...otherProps}
    >
      <Modal.Header closeButton>
        <Modal.Title className="ml-auto">Detaljeret information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <GeneralOrderDataTable data={generalOrderData} />
          </Col>
          <Col xs={12}>
            <PortfolioEtfOrdersTable data={portfolioEtfOrders} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Text as="h5">Allokering</Text>
          </Col>
          <Col xs={12} lg={5}>
            <AllocationDataTable data={allocationData} />
          </Col>
          <Col xs={12} lg={7}>
            <AllocationAccountsDataTable data={participatingAccountsInfo} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default OrderModal
