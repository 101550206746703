import FullScreenSection from '@nord/ui/src/components/FullScreenSection'
import React from 'react'
import Container from 'react-bootstrap/Container'
import { Outlet } from 'react-router-dom'

import Navigation from '../Navigation'

const PagesWrapper = () => (
  <FullScreenSection topSection>
    <Navigation />
    <Container className="mb-5" fluid>
      <Outlet />
    </Container>
  </FullScreenSection>
)

export default PagesWrapper
