import Allocation from './Allocation'
import useAllocationValue from './useAllocationValue'

const columns = [
  {
    Header: 'ETFs',
    accessor: 'name',
  },
  {
    Header: 'Nuværende',
    accessor: 'currentAllocation',
    Cell: ({ cell: { value } }: any) => useAllocationValue(value),
  },
  {
    Header: 'Ændring',
    accessor: 'allocationAfterTradeDiff',
    Cell: Allocation.AfterTradeDifference,
  },
  {
    Header: 'Efter handel',
    accessor: 'allocationAfterTrade',
    Cell: Allocation.AfterTrade,
  },
  {
    Header: 'Ønskede',
    accessor: 'targetAllocation',
    Cell: Allocation.WithDeviationRange,
  },
]

export default columns
