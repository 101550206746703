import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import Table from 'react-bootstrap/Table'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useTable, usePagination, useExpanded, useSortBy, useFilters } from 'react-table'

import Pagination from '../../../../components/Pagination'
import ScrollableTable from '../../../../components/ScrollableTable'
import SortIcon from '../../InvestablePortfoliosPage/SortIcon'
import { useUsersContext } from '../UsersContext'
import useSaxoHouseTransferPreferences from '../useSaxoHouseTransferPreferences'

import columns from './columns'

const defaultItems = 20

const defaultColumn = {
  Filter: () => null,
}

const formatSorting = (isSorted?: boolean, isSortedDesc?: boolean) => {
  if (!isSorted) return undefined

  return isSortedDesc ? 'desc' : 'asc'
}

interface Row {
  id: number
}

export interface UsersTableProps {
  data?: Row[]
}

const UsersTable = ({ data }: UsersTableProps) => {
  const { apiFilter, loading, getUsers, pageCount } = useUsersContext()

  const {
    filters: [apiFilters, setApiFilters],
    sort: [apiSort, setApiSort],
    sortBy,
  } = useSaxoHouseTransferPreferences()

  const initialState = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: defaultItems,
      sortBy,
      filters: apiFilters,
    }),
    [sortBy, apiFilters],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      autoResetPage: false,
      autoResetSortBy: false,
      columns,
      defaultColumn,
      data,
      initialState,
      manualPagination: true,
      manualSortBy: true,
      pageCount,
      manualFilters: true,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  )

  useEffect(() => {
    setApiFilters(filters)
  }, [filters, setApiFilters])

  useEffect(() => {
    getUsers({
      page: {
        limit: pageSize,
        index: pageIndex + 1,
      },
      filter: apiFilter,
      sort: apiSort,
    })
  }, [apiFilter, apiSort, pageIndex, pageSize, getUsers])

  const handleSort = async (column: any) => {
    const { canSort, toggleSortBy, id } = column

    if (!canSort) return

    await toggleSortBy()

    const { isSorted, isSortedDesc } = column

    const sortValue = formatSorting(isSorted, isSortedDesc)

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ [x: number]: string | undefine... Remove this comment to see the full error message
    setApiSort({ [id]: sortValue })
  }

  return (
    <>
      <ScrollableTable.Wrapper loading={loading}>
        <Table bordered striped hover {...getTableProps()}>
          <ScrollableTable.TableHead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps()}>
                    <span
                      role="none"
                      {...column.getSortByToggleProps()}
                      onClick={() => handleSort(column)}
                    >
                      {column.render('Header')}
                    </span>
                    <SortIcon column={column} />
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </ScrollableTable.TableHead>
          <ScrollableTable.TableBody {...getTableBodyProps()}>
            {isEmpty(rows) ? (
              <ScrollableTable.NoResultsMessage loading={loading} />
            ) : (
              rows.map((row: any) => {
                prepareRow(row)

                return (
                  <tr {...row.getRowProps()} data-cy={row.original.id}>
                    {row.cells.map((cell: any) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                )
              })
            )}
          </ScrollableTable.TableBody>
        </Table>
      </ScrollableTable.Wrapper>
      <Pagination.Wrapper>
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageSize={pageSize}
          pageCount={pageCount}
          pageIndex={pageIndex}
          previousPage={previousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          className="my-5"
        />
      </Pagination.Wrapper>
    </>
  )
}

UsersTable.defaultProps = {
  data: [],
}

export default UsersTable
