import React from 'react'

import IndeterminateCheckbox from '../../../../components/IndeterminateCheckBox'
import { useInvestablePortfoliosContext } from '../InvestablePortfoliosContext'

import { selectionLimit } from './constants'

const getTitleAttribute = (pendingOrders: any, disabledSelection: any) => {
  const startOfSentence = 'Kan ikke vælges'

  if (pendingOrders) return `${startOfSentence} fordi der er igangværende handler`
  if (disabledSelection)
    return `${startOfSentence} fordi du allerede har valgt det maksimale antal (${selectionLimit})`

  return 'Vælg for at handle denne portfolio'
}

export interface Props {
  row: {
    getToggleRowSelectedProps?: (...args: any[]) => any
    isSelected: boolean
    original?: {
      pendingOrders?: boolean
      suitable?: boolean
    }
  }
  state: {
    selectedRowIds?: any
  }
}

const Cell = ({
  row: {
    getToggleRowSelectedProps,
    // @ts-expect-error TS(2339) FIXME: Property 'pendingOrders' does not exist on type '{... Remove this comment to see the full error message
    original: { pendingOrders },
    isSelected,
  },
  state: { selectedRowIds },
}: Props) => {
  const { loading } = useInvestablePortfoliosContext()

  const currentSelectionLength = Object.keys(selectedRowIds).length
  const disabledSelection = currentSelectionLength >= selectionLimit && !isSelected

  const title = getTitleAttribute(pendingOrders, disabledSelection)

  return (
    <div title={title}>
      <IndeterminateCheckbox
        disabled={pendingOrders || loading || disabledSelection}
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        {...getToggleRowSelectedProps()}
      />
    </div>
  )
}

export default Cell
