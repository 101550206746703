import Form from '@nord/ui/src/components/Form'
import Text from '@nord/ui/src/components/Text'
import useApi from '@nord/ui/src/hooks/useApi'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAppContext } from '../../../context/AppContext'

import validationSchema from './validationSchema'

const labelDescriptions = {
  email: 'Email',
  password: 'Adgangskode',
}

const LoginForm = () => {
  const { setCurrentUser } = useAppContext()

  const signIn = useApi('/admin_users/sign_in', {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        unauthorized: true,
      },
    },
  })

  const [searchParams] = useSearchParams()
  const emailParam = searchParams.get('email')
  const initialValues = { email: emailParam }

  const handleSignIn = async ({ email, password }: { email: string; password: string }) => {
    const dataForSignIn = {
      adminUser: { email, password },
    }

    const response = await signIn(dataForSignIn)

    const { success, data } = response

    if (success) setCurrentUser(data)

    return { ...response, showResponseError: true }
  }

  return (
    <>
      <Text as="h2" align="center" size="xl" className="mb-5">
        Velkommen til NORD admin
      </Text>
      <Form
        onSubmit={handleSignIn}
        initialValues={initialValues}
        validationSchema={validationSchema}
        labelDescriptions={labelDescriptions}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; onSubmit: ({ email, p... Remove this comment to see the full error message
        validateOnMount
        withSingleError
        enableReinitialize
      >
        <Form.FieldGroup name="email">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ type: string; }' is not assignable to type... Remove this comment to see the full error message */}
          <Form.Field type="email" />
        </Form.FieldGroup>
        <Form.FieldGroup name="password">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ type: string; }' is not assignable to type... Remove this comment to see the full error message */}
          <Form.Field type="password" />
        </Form.FieldGroup>
        <div className="d-flex justify-content-center py-3">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; }' is n... Remove this comment to see the full error message */}
          <Form.SubmitButton variant="primary">Log ind</Form.SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default LoginForm
