import BaffleButton from '@nord/ui/src/components/BaffleButton'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import type { BatchTradeTypeType } from '../../../../types'
import { humanizedTradeType } from '../../../../utils'

export interface TradingButtonProps {
  dropdown?: boolean
  portfolioIds: number[]
  tradeType: BatchTradeTypeType
  disabled?: boolean
}

const TradingButton = ({ tradeType, portfolioIds, dropdown, disabled }: TradingButtonProps) => {
  const navigate = useNavigate()

  const handleReadyToTrade = () =>
    navigate(`/portfolios/trades?portfolio_ids=${portfolioIds}&trade_type=${tradeType}`)

  const isDisabled = disabled || isEmpty(portfolioIds)

  const text = humanizedTradeType(tradeType)

  if (dropdown) {
    return (
      <Dropdown.Item disabled={isDisabled} onClick={handleReadyToTrade}>
        {text}
      </Dropdown.Item>
    )
  }

  return (
    <BaffleButton
      variant="success"
      data-cy="buy-button"
      size="sm"
      onClick={handleReadyToTrade}
      disabled={isDisabled}
      filled
    >
      {text}
    </BaffleButton>
  )
}

TradingButton.defaultProps = {
  dropdown: false,
}

export default TradingButton
