import React from 'react'
import BootstrapTable from 'react-bootstrap/Table'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useTable } from 'react-table'

export interface Props {
  data?: Record<string, unknown>[]
  hide?: string[]
  columns: {
    Header?: string
    accessor?: string
  }[]
}

const Table = ({ columns, data, hide, ...otherProps }: Props) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <BootstrapTable bordered striped hover responsive {...otherProps} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows
          .filter((row: any) => (hide ? !hide.includes(row.original.name) : true))
          .map((row: any) => {
            prepareRow(row)

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            )
          })}
      </tbody>
    </BootstrapTable>
  )
}

Table.defaultProps = {
  data: [],
  hide: [],
}

export default Table
