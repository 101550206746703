import NoResultsMessage from './NoResultsMessage'
import TableBody from './TableBody'
import TableHead from './TableHead'
import Wrapper from './Wrapper'

const ScrollableTable = {
  NoResultsMessage,
  TableBody,
  TableHead,
  Wrapper,
}

export default ScrollableTable
