import { assertNever } from '@nord/ui/src/utilities'

import type { CustomTradeTypeType, BatchTradeTypeType } from '../types'

const humanizedTradeType = (tradeType: CustomTradeTypeType | BatchTradeTypeType) => {
  switch (tradeType) {
    case 'buy':
      return 'Køb'
    case 'sell':
      return 'Sælg'
    case 'rebalance':
      return 'Rebalancer'
    case 'risk_profile_change':
      return 'Skift af risikoprofil'
    case 'custom':
      return 'Brugerdefineret'
    default:
      assertNever(tradeType)

      return tradeType
  }
}

export default humanizedTradeType
