import constate from 'constate'
import isEmpty from 'lodash/isEmpty'
import { useState } from 'react'

import useCurrentPortfolioIds from '../../../hooks/useCurrentPortfolioIds'
import useTradePreferences from '../../../hooks/useTradePreferences'
import useTradeType from '../../../hooks/useTradeType'

import usePortfoliosAllocationDataChannel from './hooks/usePortfoliosAllocationDataChannel'

const usePortfoliosTrades = () => {
  const tradeType = useTradeType()
  const currentPortfolioIds = useCurrentPortfolioIds()
  const [acceptedPortfolioStatus, setAcceptedPortfolioStatus] = useState<Record<number, boolean>>(
    {},
  )
  const [showOverviewModal, setShowOverviewModal] = useState(false)
  const handleShowOverviewModal = () => setShowOverviewModal(true)
  const handleHideOverviewModal = () => setShowOverviewModal(false)
  const { portfoliosAllocationData } = usePortfoliosAllocationDataChannel(tradeType)

  const {
    displayType: [displayType, setDisplayType],
  } = useTradePreferences()

  const isLoadingPortfolioAllocations =
    !isEmpty(portfoliosAllocationData) &&
    Object.keys(portfoliosAllocationData).length !== currentPortfolioIds.length

  return {
    acceptedPortfolioStatus,
    displayType,
    isLoadingPortfolioAllocations,
    portfoliosAllocationData,
    showOverviewModal,
    onDisplayTypeChange: setDisplayType,
    onHideOverviewModal: handleHideOverviewModal,
    onShowOverviewModal: handleShowOverviewModal,
    setAcceptedPortfolioStatus,
  }
}

export const [PortfoliosTradesProvider, usePortfoliosTradesContext] = constate(usePortfoliosTrades)
