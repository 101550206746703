import Text from '@nord/ui/src/components/Text'
import { getApiClient } from '@nord/ui/src/utilities'
import isObject from 'lodash/isObject'
import upperFirst from 'lodash/upperFirst'
import React from 'react'
import { useLoaderData, type LoaderFunctionArgs } from 'react-router-dom'
import invariant from 'tiny-invariant'

import Table from '../../../components/Table'
import { humanizedBoolean, humanizedDate, isDateValue } from '../../../utils'

const client = getApiClient()

const humanizeDetail = (detail: string) => upperFirst(detail.replace(/([A-Z])/g, ' $1'))

const humanizeValue = (value: unknown) => {
  if (isDateValue(value)) {
    return humanizedDate(value)
  }

  if (typeof value === 'boolean') {
    return humanizedBoolean(value)
  }

  if (value == null) {
    return 'N/A'
  }

  if (Array.isArray(value) || isObject(value)) {
    return JSON.stringify(value)
  }

  return value
}

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  invariant(params.id)

  const { data } = await client.get(`/limited_admin/users/${params.id}`, {
    withCredentials: true,
    signal: request.signal,
  })

  return {
    title: `${data.firstName} ${data.lastName}`,
    columns: [
      { accessor: 'detail', Header: 'Detaljer' },
      { accessor: 'value', Header: 'Kunde' },
    ],
    data: Object.entries(data).map(([detail, value]) => ({
      detail: humanizeDetail(detail),
      value: humanizeValue(value),
    })),
  }
}

const UsersShowPage = () => {
  const { title, data, columns } = useLoaderData() as Awaited<ReturnType<typeof loader>>

  return (
    <>
      <Text as="h3" variant="dark">
        {title}
      </Text>
      <Table data={data} columns={columns} />
    </>
  )
}

export default UsersShowPage
