import Text from '@nord/ui/src/components/Text'
import React from 'react'
import { Card } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'

import Page from '../../../components/Page'

import { useUsersContext } from './UsersContext'
import UsersTable from './UsersTable'

const SaxoHouseTransfersPage = () => {
  const { usersData } = useUsersContext()

  return (
    <Page xs={12}>
      <Card className="p-4">
        <div className="m-5 d-flex align-items-center justify-content-center">
          <Text as="h2" variant="dark" align="center">
            Ordrer
          </Text>
        </div>
        <UsersTable data={usersData} />
        <Outlet />
      </Card>
    </Page>
  )
}

export default SaxoHouseTransfersPage
