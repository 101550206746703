import keyBy from 'lodash/keyBy'
import { useEffect, useState } from 'react'
import z from 'zod'

import useCurrentPortfolioIds from '../../../../hooks/useCurrentPortfolioIds'
import useDataChannel from '../../../../hooks/useDataChannel'
import type {
  AllocationType,
  UserType,
  PortfolioType,
  BatchTradeTypeType,
  BatchGenerateAllocationDataPayloadType,
} from '../../../../types'
import { BatchPortfolioAllocationsChannelData } from '../../../../types/PortfolioAllocationsChannelData'
import { assertZod } from '../../../../utils'

const orderPortfoliosAllocationData = (portfolioIds: number[]) =>
  keyBy(
    portfolioIds.map((id, index) => ({
      index,
      portfolioId: id,
      portfolio: {} as PortfolioType,
      user: {} as UserType,
      allocations: [] as AllocationType[],
    })),
    'portfolioId',
  )

export type PortfolioAllocationsDataType = ReturnType<
  typeof usePortfoliosAllocationDataChannel
>['portfoliosAllocationData']

const usePortfoliosAllocationDataChannel = (tradeType: BatchTradeTypeType) => {
  const currentPortfolioIds = useCurrentPortfolioIds()

  const [portfoliosAllocationData, setPortfoliosAllocationData] = useState(
    orderPortfoliosAllocationData(currentPortfolioIds),
  )

  const { subscribe, unsubscribe, send } = useDataChannel({
    channel: 'PortfoliosAllocationDataChannel',
    topic: [...currentPortfolioIds].sort().join(','),
  })

  useEffect(() => {
    subscribe({
      received(res) {
        const { data } = assertZod(z.object({ data: BatchPortfolioAllocationsChannelData }), res)

        const currentId = data.portfolio.id

        setPortfoliosAllocationData((currentData) => {
          const currentObject = currentData[currentId]

          return {
            ...currentData,
            [currentId]: { ...currentObject, ...data },
          }
        })
      },
    })

    return unsubscribe
  }, [subscribe, unsubscribe])

  useEffect(() => {
    const payload: BatchGenerateAllocationDataPayloadType = {
      portfoliosData: currentPortfolioIds.map((id) => ({ id })),
      tradeType,
    }

    send({ action: 'generate_allocation_data', payload })
  }, [currentPortfolioIds, tradeType, send])

  return { portfoliosAllocationData }
}

export default usePortfoliosAllocationDataChannel
