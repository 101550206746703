import { useQuery } from '@nord/ui/src/hooks'

import { BatchTradeType } from '../types'

const useTradeType = () => {
  const tradeType = BatchTradeType.parse(useQuery('tradeType') ?? 'buy')

  return tradeType
}

export default useTradeType
