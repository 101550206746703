import Text from '@nord/ui/src/components/Text'
import React from 'react'

import useAllocationValue from '../useAllocationValue'
import useFormatAllocationValue from '../useFormatAllocationValue'

export interface AfterTradeDifferenceProps {
  row: {
    original: {
      currentAllocation: Record<string, number>
      allocationAfterTrade: Record<string, number>
    }
  }
}

const AfterTradeDifference = ({
  row: {
    original: { currentAllocation, allocationAfterTrade },
  },
}: AfterTradeDifferenceProps) => {
  const currentAllocationValue = useAllocationValue(currentAllocation, false)
  const allocationAfterTradeValue = useAllocationValue(allocationAfterTrade, false)
  const differenceAfterTrade = allocationAfterTradeValue - currentAllocationValue

  const formattedDifferenceAfterTrade = useFormatAllocationValue(differenceAfterTrade)

  if (differenceAfterTrade === 0) return '-'
  if (differenceAfterTrade < 0) return <Text variant="danger">{formattedDifferenceAfterTrade}</Text>

  return <Text variant="dark-green">+{formattedDifferenceAfterTrade}</Text>
}

export default AfterTradeDifference
